import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const CharacterContainer = styled.section(
	() => css`
		position: fixed;
		top: 5vh;
		z-index: 2;
		width: 100%;
		display: grid;
		grid-template-columns: auto auto;
		justify-content: center;
		.char {
			width: 300px;
			position: absolute;
 			inset-inline-end: 10%;
			z-index: 2;
		}
		.char_with_pet {
			width: 300px;
			position: absolute;
 			inset-inline-end: 25%;
			z-index: 2;
		}
		.pet {
			width: 300px;
			position: absolute;
			justify-self: start;
			top: -20px;
			inset-inline-end: 13%;
			z-index: 1;
		}
		@media (max-width: 360px) {
			.pet {
				width: 250px;
			}
			.char_with_pet {
			width: 250px; 
		}
		}
	 
	`,
);
