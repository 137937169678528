import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const EarnContainer = styled.div(
	() => css`
		padding: 24px 16px 120px;
		background: #01264f;
		position: relative;
	`,
);
