import { useEffect, useState } from 'react';
import { AvatarShopContainer } from './css';
import { Header, Games, Character, Buy, Tabs, Cards } from './components';
import { useGetAssetsQuery, useGetAvatarsQuery } from 'store/query';
import Loader from 'shared/components/loader';
import { RootState } from 'store/types';
import { useSelector } from 'react-redux';

const AvatarShop = () => {
	const [activeTab, setActiveTab] = useState<'pet' | 'armor'>('armor');
	const { data: avatarsData } = useGetAvatarsQuery();
	const [selectedCard, setSelectedCard] = useState({
		iconUrl: { pet: null, armor: null },
		price: { diamond: 0, food: 0, prana: 0, education: 0 },
		id: -1,
		status_: -1,
	});
	const { refetch } = useGetAssetsQuery(activeTab);

	const incomingNotif = useSelector((state: RootState) => state.signalR);
	useEffect(() => {
		if (incomingNotif?.data?.event === 'Notification') {
			refetch();
		}
	}, [incomingNotif, incomingNotif.data.event, incomingNotif.data.data]);
	return (
		<AvatarShopContainer>
			{!avatarsData && <Loader />}
			{avatarsData && <Header name={avatarsData.displayName} gem={avatarsData.score.diamond} />}
			<Games />
			{avatarsData?.assets.armor && (
				<Character
					key={`${selectedCard.iconUrl.armor}`}
					imageUrlChar={selectedCard.iconUrl.armor ?? avatarsData.assets.armor.iconCompleteUrl}
					imageUrlPet={selectedCard.iconUrl.pet ?? avatarsData.assets?.pet?.iconCompleteUrl}
				/>
			)}
			{avatarsData?.score && (
				<Buy
					status_={selectedCard.status_}
					score={avatarsData.score}
					type={activeTab === 'pet' ? 1 : 2}
					price={selectedCard.price}
					id={selectedCard.id}
				/>
			)}
			<Tabs setActiveTab={setActiveTab} />
			<Cards
				category={activeTab}
				onClick={value => {
					setSelectedCard(perv => ({
						...perv,
						iconUrl: { ...perv.iconUrl, [activeTab]: value.iconUrl[activeTab] },
						id: value.id,
						price: {
							diamond: value.price.diamond ?? 0,
							food: value.price.food ?? 0,
							education: value.price.education ?? 0,
							prana: value.price.prana ?? 0,
						},
						status_: value.status_,
					}));
				}}
			/>
		</AvatarShopContainer>
	);
};

export default AvatarShop;
