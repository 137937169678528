import { InfoContainer } from '../css';
import { useAllExchangesQuery, useGetAvatarsQuery } from 'store/query';

const Info = () => {
	const { data: name } = useGetAvatarsQuery();
	const { data: allExchanges } = useAllExchangesQuery();
	const logo = allExchanges?.exchanges.filter(i => i.name === allExchanges.user.exchange)[0];

	return (
		<InfoContainer>
			<p className='name'>Name: {name?.displayName} </p>
			
				<div className='detail'>
					<img src={name?.assets.armor.iconCompleteUrl} alt='' />
					<div className='wallet_info'>
						<div className='container'>
							<div className='exchange'>
								Exchange:
								<div className='box'>
									{logo&&<img src={logo?.iconUrl??''} alt='' /> }<span>{logo?.name??'...'}</span>
								</div>
							</div>
							<div className='exchange'>
								UID: <div className='box'>{allExchanges?.user.uid??'...'}</div>
							</div>
						</div>

						<div className='wallet_address'>
							Wallet Address:
							<div className='box'>{allExchanges?.user.walletAddress?allExchanges.user.walletAddress:'...'}</div>
						</div>
					</div>
				</div>
			
		</InfoContainer>
	);
};

export default Info;
