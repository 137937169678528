import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const ShopPranaContainer = styled.div(
	() => css`
		color: #fff;
		position: relative;
		background: #01264f;
		padding-bottom: 350px;
		overflow: hidden;
		height: 100vh;
		.banner {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			margin: auto;
			width: 100%;
		}
		.games {
			display: grid;
			grid-template-columns: 1fr 1fr;
			padding: 0 16px;
			position: fixed;
			top: 75px;
			inset-inline-start: 0;
			justify-content: space-between;
			width: 100%;
			color: #fff;
			z-index: 0;
			span {
				font-weight: bold;
				font-size: 17px;
				line-height: 21.05px;
				letter-spacing: 0px;
				text-align: center;
			}
			.ed {
				img {
					position: absolute;
					top: -30%;
					inset-inline-start: 13%;
					width: 54px;
					height: 54px;
				}
				font-weight: bold;
				font-size: 11px;
				line-height: 13.62px;
				letter-spacing: 0px;
				text-align: center;
				gap: 4px;
				position: relative;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				width: 70px;
				height: 75px;
				padding: 18px 6px 0px 6px;
				border-radius: 13px;
				background: linear-gradient(180deg, #3cb1fa 30%, #045587 77%);
			}
			.prana {
				justify-self: end;
			}
		}
	
	`,
);
