import { AssetType, POSTBuyAsset, POSTSelectAsset } from 'api/v1/assets';
import { BuyContainer } from '../css';
import ButtonNew from 'shared/components/button_new';
import { updateAssets, updateAvatars } from 'store/query';
import { useAppDispatch } from 'shared/hooks/redux-helper';
import { useEffect, useState } from 'react';
import { IGetAvatars } from 'api/v1/avatar';

interface BuyProps {
	price: { food?: number; diamond?: number; education?: number; prana?: number };
	id: number;
	type: AssetType;
	score: IGetAvatars['score'];
	status_?: number;
}

const Buy: React.FC<BuyProps> = ({ score, price, id, type, status_ }) => {
	const dispatch = useAppDispatch();
	const [loading, setLoading] = useState(false);
	const [hidden, setHidden] = useState(false);

	useEffect(() => {
		setHidden(false);
	}, [id]);
	const handleSelectCard = (id: number) => {
		POSTSelectAsset(id).then(res => {
			setHidden(true);
			dispatch(updateAvatars({ asset: res.assets }));
			dispatch(updateAssets({ type, id, status: 2 }));
		});
	};
	const handleBuy = () => {
		setLoading(true);
		POSTBuyAsset(id)
			.then(() => {
				dispatch(
					updateAssets({
						id: id,
						type: type,
						status: 4,
					}),
				);
			})
			.finally(() => setLoading(false));
	};
	return (
		<BuyContainer>
			{hidden ||
				(id !== -1 && status_ !== 2 && (
					<>
						{status_ === 3 ? (
							<div></div>
						) : (
							<div className='cost'>
								{(price?.diamond ?? 0) > 0 && <>💎 {price?.diamond}</>}
								{(price?.education ?? 0) > 0 && (
									<>
										<span> |</span> 🎓 {price?.education}
									</>
								)}
								{(price?.food ?? 0) > 0 && (
									<>
										<span>| </span> <>🌾 {price?.food}</>
									</>
								)}
								{(price?.prana ?? 0) > 0 && (
									<>
										<span>|</span> <>🏡 {price?.prana}</>
									</>
								)}
							</div>
						)}
						<ButtonNew
							isLoading={loading}
							variant='primary'
							className='buy'
							disabled={
								id === -1 ||
								(price.diamond && score.diamond < price.diamond) ||
								(price.food && score.food < price.food) ||
								(price.education && score.education < price.education) ||
								(price.prana && score.prana < price.prana) ||
								status_ === 4
							}
							onClick={e => {
								e.preventDefault();
								if (status_ === 3) {
									handleSelectCard(id);
								} else {
									handleBuy();
								}
							}}
						>
							{status_ === 3 ? 'Select' : 'Buy'}
						</ButtonNew>
					</>
				))}
		</BuyContainer>
	);
};

export default Buy;
