import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const InfoModalContainer = styled.section(
	({ theme }) => css`
		color: ${theme.colors.text.primary};
		padding: 0 24px 32px;
		position: relative;
		text-align: center;
		color: ${theme.colors.text.primary};
		display: flex;
		flex-direction: column;
		z-index: 5;
		.calender {
			width: 130px;
			height: 130px;
			position: absolute;
			top: -135px;
			left: 0;
			right: 0;
			margin: auto;
		}
		.content {
			text-align: center;
			color: ${theme.colors.text.primary};
			display: flex;
			flex-direction: column;
			gap: 24px;

			h3 {
				font-size: 24px;
				font-weight: 500;
				line-height: 29.71px;
				text-align: center;
			}

			p {
				font-size: 14px;
				font-weight: 400;
				line-height: 20px;
				text-align: center;
				text-justify: center;
 				justify-self: center;
				/* padding: 0 24px; */
			}
				.btns{
				display: flex;
				justify-content: space-between;
					 
					button {
					width: 45%;
					font-size: 18px;
					font-weight: 500;
					line-height: 35.28px;
					}
			}
		}
		@media (max-height: 667px) {
        padding: 0 24px 10px;
        gap: 16px;
        .calender {
            width: 130px;
            height: 130px;
            top: -120px;
        }
        .content{
            height: 200px;
            overflow: auto;
            display: flex;
            flex-direction: column;
            gap: 16px;
            h3 {
                padding-top:20px;
            line-height: 20px;
            font-size: 20px;
            }
            p {
            line-height: 20px;
            font-size: 14px:
            }
        
        }
    }
	`,
);
