import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const WalkthroughContainer = styled.div(
	({ theme }) => css`
		height: 100vh;
		background: #fff;
		 
		.mySwiper {
			height: calc(100% - 40px);
 
			.slider_item {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				color: ${theme.colors.text.primary};
				height: 100%;

			 
				.img_container {
					position: relative;
					&::after {
						position: absolute;
						left: 0;
						right: 0;
						bottom: 0;
						height: 100px;
						content: '';
						background: #fff;
					}
					img {
						width: 100%;
					}
				}
			}
		}

		.swiper-pagination-bullet {
			background: #0D5AA4;
			opacity: 0.3;

			&.swiper-pagination-bullet-active {
				opacity: 1;
			}
		}

		.next_btn {
			width: 92% ;
			bottom:13%;
			position: fixed;
			z-index: 1;
			inset-inline-start:16px;
 		}

		 
	`,
);
