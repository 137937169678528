import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const CardsContainer = styled.section(
	() => css`
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
		grid-auto-rows: 150px;
		gap: 8px;
		position: relative;
		top: 50vh;
		overflow-y: auto;
		scrollbar-width: thin;
		scrollbar-color: transparent;
		padding-bottom: 120px;
		margin-top: 4px;
		flex-grow: 1;
		height: calc(100vh - 120vw);
		border-radius: 12px;
		@media (max-width: 350px) {
			top: 49vh;
		}
	`,
);
