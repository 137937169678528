import { GETAvatar, IGetAvatars } from 'api/v1/avatar';
import { AppDispatch } from 'store/types';
import { createCustomApi } from 'store/base.api';

const apis = {
	avatars: GETAvatar,
};

const asyncBaseQuery = async ({ path }: { path: keyof typeof apis }) => {
	const data = await apis[path]();
	return { data: data };
};

const AvatarSliceApi = createCustomApi({
	reducerPath: 'avatars-api',
	baseQuery: asyncBaseQuery,
	endpoints: builder => ({
		getAvatars: builder.query<IGetAvatars, void>({
			query: () => ({ path: 'avatars' }),
		}),
	}),
});

export const updateAvatars = (data: { score?: IGetAvatars['score']; asset?: IGetAvatars['assets'] }) => (dispatch: AppDispatch) => {
	dispatch(
		AvatarSliceApi.util.updateQueryData('getAvatars', undefined, draft => {
			if (data.score) draft.score = data.score;
			if (data.asset) draft.assets = data.asset;
		}),
	);
};

export const { useGetAvatarsQuery } = AvatarSliceApi;
export default AvatarSliceApi;
