import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const LayoutContainer = styled.div(
	() => css`
		min-height: 100vh;
		height: 100%;
		position: relative;

		iframe {
			position: absolute;
			bottom: 150px;
			left: 0;
			right: 0;
			margin: auto;
		}

		/* .bottom_right_light {
			background: #91cddb66;
			filter: blur(100px);
			width: 203px;
			height: 203px;
			position: fixed;
			left: -65px;
			bottom: -73px;
			z-index: 2;
			pointer-events: none;
			touch-action: none;
		} */
	`,
);
