import {
	GETAirdropInfo,
	IGetAirdropInfo,
	GETAirdropInfoIsEligible,
	GETAirdropInfoGetFinal,
	IGetAirdropInfoIsEligible,
	IGetAirdropInfoGetFinal,
} from 'api/v1/airdrop-info';
import { GETGetAllTokenBalance, IGetAllTokenBalanceResponse } from 'api/v1/token-balance';
import { GETAllWallets, GETHasVipWallet, IHasVipWallet, IWalletResponse } from 'api/v1/wallet';
import { createCustomApi } from 'store/base.api';

const apis = {
	wallets: GETAllWallets,
	tokenBalance: GETGetAllTokenBalance,
	vip: GETHasVipWallet,
	airdropInfo: GETAirdropInfo,
	airdropIsEligible: GETAirdropInfoIsEligible,
	airdropGetFinal: GETAirdropInfoGetFinal,
};

const asyncBaseQuery = async ({ path }: { path: keyof typeof apis }) => {
	const data = await apis[path]();
	return { data: data };
};

const WalletSliceApi = createCustomApi({
	reducerPath: 'wallets-api',
	baseQuery: asyncBaseQuery,
	endpoints: builder => ({
		wallets: builder.query<IWalletResponse[], void>({
			query: () => ({ path: 'wallets' }),
		}),
		tokenBalance: builder.query<IGetAllTokenBalanceResponse[], void>({
			query: () => ({ path: 'tokenBalance' }),
		}),
		vip: builder.query<IHasVipWallet | null, void>({
			query: () => ({ path: 'vip' }),
		}),
		airdropInfo: builder.query<IGetAirdropInfo, void>({
			query: () => ({ path: 'airdropInfo' }),
		}),
		airdropIsEligible: builder.query<IGetAirdropInfoIsEligible, void>({
			query: () => ({ path: 'airdropIsEligible' }),
		}),
		airdropGetFinal: builder.query<IGetAirdropInfoGetFinal, void>({
			query: () => ({ path: 'airdropGetFinal' }),
		}),
	}),
});

export const {
	useWalletsQuery,
	useTokenBalanceQuery,
	useVipQuery,
	useAirdropInfoQuery,
	useAirdropGetFinalQuery,
	useAirdropIsEligibleQuery,
} = WalletSliceApi;

export default WalletSliceApi;
