import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const SpecialContainer = styled.section(
	({ theme }) => css`
		color: ${theme.colors.text.primary};

		.special_title {
			font-weight: 700;
			font-size: 16px;
			line-height: 20px;
			margin-bottom: 12px;
		}

		.other_tasks {
			margin-top: 11px;
			border-radius: 22px;
			background: #023166;
			padding: 16px 10px 12px;
			position: relative;
			border: 1px solid #f5c21d;
			.special_card {
				background: #0d5aa4;
				border: 1px solid #f5c21d;
				border-radius: 15px;
				padding: 12px 20px;
				display: grid;
				grid-template-columns: 55px 1fr 24px;
				grid-template-rows: auto auto;
				gap: 5px 14px;
				margin-bottom: 12px;

				&:last-of-type {
					margin-bottom: 0;
				}

				.img_container {
					grid-row: 1/3;
					width: 55px;
					height: 55px;
					background: transparent;
					display: flex;
					align-items: center;
					justify-content: center;
					margin: auto;
					svg,
					img {
						width: 55px;
						height: 55px;
					}
				}

				.title {
					font-weight: 600;
					font-size: 14px;
					line-height: 20px;
					margin: auto auto 0 0;
				}
				.value {
					font-weight: 500;
					font-size: 14px;
					line-height: 17px;
					color: ${theme.colors.text.primary};
					display: flex;
					align-items: flex-start;
					gap: 5px;
					grid-row: 2;
					margin: 0 auto auto 0;

					&.green {
						color: #28f39e;
					}

					img {
						width: 14px;
					}
				}

				svg {
					grid-row: 1/3;
					margin: auto;

					&.green {
						color: #28f39e;
					}
				}
			}
		}

		@media (max-width: 360px) {
			.border {
				.other_tasks {
					.special_card {
						padding: 12px 10px;
						grid-template-columns: 44px 1fr 24px;
						.img_container {
							svg,
							img {
								width: 44px;
								height: 44px;
							}
						}
						.title {
							font-size: 12px;
						}
					}
				}
			}
		}
	`,
);
