import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const PineyeContainer = styled.section(
	() => css`
	 display: flex;
		align-items: center;
		justify-content: center;
		position: fixed;
		top:11%;
		z-index: 2;
		inset-inline-start:0;

		.armor {
			width: 98%;
			position: relative;
			z-index: 4;
			top: -10px;
			&.whit_pet {
				inset-inline-start: -12%;
			}
		}

		.pet {
			width: 100%;
			position: absolute;
			z-index: 4;
			top: -35px;
			inset-inline-start: 4%;
			z-index: -1;
		}
	 
	`,
);
