import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from './types';

export const joinAcademy = createAsyncThunk('signalR/joinAcademy', async (params: { topic: string; username: string }, thunkAPI) => {
	const { getState, rejectWithValue } = thunkAPI;
	const { topic, username } = params;
	const state = getState() as RootState;
	const connection = state.signalR.connection;

	if (!connection) {
		return rejectWithValue('No connection established');
	}

	try {
		await connection.invoke('JoinAcademy', topic, username);
		console.log('Joined academy:', topic);
	} catch (err) {
		console.error('Failed to join academy:', err);
		return rejectWithValue(err);
	}
});

export const leaveAcademy = createAsyncThunk('signalR/leaveAcademy', async (_, { getState, rejectWithValue }) => {
	const state = getState() as RootState;
	const connection = state.signalR.connection;

	if (!connection) {
		return rejectWithValue('No connection established');
	}

	try {
		await connection.invoke('LeaveAcademy');
		console.log('Left academy.');
	} catch (err) {
		console.error('Failed to leave academy:', err);
		return rejectWithValue(err);
	}
});

export const gameAccept = createAsyncThunk('signalR/gameAccept', async (isAccepted: boolean, { getState, rejectWithValue }) => {
	const state = getState() as RootState;
	const connection = state.signalR.connection;
	if (!connection) {
		return rejectWithValue('No connection established');
	}
	try {
		await connection.invoke('GameAccept', isAccepted);
		console.log('Game Accept:', isAccepted);
	} catch (err) {
		console.error('Failed to Game Accept:', err);
		return rejectWithValue(err);
	}
});

export const sendNewquestion = createAsyncThunk(
	'signalR/sendNewquestion',
	async ({ gameId }: { gameId: string; }, { getState, rejectWithValue }) => {
		const state = getState() as RootState;
		const connection = state.signalR.connection;
		if (!connection) {
			return rejectWithValue('No connection established');
		}
		try {
			await connection.invoke('SendNewquestion', gameId,);
			console.log('  Send GameUpdate:', gameId,);
		} catch (err) {
			console.error('Failed to SendNewquestion:', err);
			return rejectWithValue(err);
		}
	},
);

export const playerAnswer = createAsyncThunk(
	'signalR/playerAnswer',
	async (
		{ gameId, questionId, answerIndex }: { gameId: string; questionId: number; answerIndex: number },
		{ getState, rejectWithValue },
	) => {
		const state = getState() as RootState;
		const connection = state.signalR.connection;
		if (!connection) {
			return rejectWithValue('No connection established');
		}
		try {
			await connection.invoke('PlayerAnswer', gameId, questionId, answerIndex);
			console.log('  Send PlayerAnswer:', gameId, questionId, answerIndex);
		} catch (err) {
			console.error('Failed to PlayerAnswer:', err);
			return rejectWithValue(err);
		}
	},
);
