import requestHandler from 'shared/utils/request-handler';
import { IGetAirdropInfo, IGetAirdropInfoIsEligible, IGetAirdropInfoGetFinal } from './airdrop-info';

const BASE_URL = '/api/v1/AirdropInfo';

export const GETAirdropInfo = async () => {
	return requestHandler.call<IGetAirdropInfo>({ url: `${BASE_URL}`, method: 'get' }).then(res => res.data);
};

export const GETAirdropInfoIsEligible = async () => {
	return requestHandler.call<IGetAirdropInfoIsEligible>({ url: `${BASE_URL}/IsEligible`, method: 'get' }).then(res => res.data);
};

export const GETAirdropInfoGetFinal = async () => {
	return requestHandler.call<IGetAirdropInfoGetFinal>({ url: `${BASE_URL}/GetFinal`, method: 'get' }).then(res => res.data);
};
