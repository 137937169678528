import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const AvatarShopContainer = styled.section(
	() => css`
		background: url(/img/avatar-shop/back.webp);
 
		background-position: top left;
		background-size: cover;
		background-repeat: no-repeat;
		padding: 21px 17px 200px;
		overflow: hidden;
		position: relative;
 		padding-bottom: 350px;
		 height: 100vh; 
		`,
);
