import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const SplashContainer = styled.div(
	() => css`
		width: 100%;
		height: 100vh;
		background-image: url(/img/sp12.webp);
		/* background-image: url(/img/maintenence-1.webp); */
		background-size: cover;
		background-position: bottom;

		padding-top: 76vh;
		p {
			text-align: center;
			position: relative;
			z-index: 3;
			color: #fff;
			font-size: 25px;
			font-weight: 600;
			line-height: 30.95px;
		}
		.timer {
			position: absolute;
			top: 35%;
			left: 0;
			right: 0;
			margin: auto;
		}
		.progress_container {
			overflow: hidden;
			width: 80%;
			height: 30px;
			border-radius: 30px;
			background: linear-gradient(227.14deg, #7dcdff -1.29%, #1fa1f0 29.94%, #0074da 87.8%);
			margin: 8px auto 8px;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 8px 11px;
			box-shadow: 0px 1px 4px 0px #ffffffb8 inset;
			box-shadow: 0px 2px 11px 0px #0000004a;
			.border {
				width: 100%;
				height: 14px;
				border-radius: 13px;
				background: #0958a8;
				box-shadow: 0px 2px 4px 0px #ffffffc2 inset, 0px 0px 6px 0px #000000;
			}

			.filled {
				height: 100%;
				border-radius: 12px;
				width: 0;
				transition: all 300ms ease;
				position: relative;
				background: linear-gradient(90deg, #6800d0 7.01%, #902bf5 31.49%, #5e6cff 63.44%, #54beff 97.94%);
				box-shadow: 0px 2px 4px 0px #ffffff inset, 0px 4px 4px 0px #002fec24;
			}
		}

		@media (max-height: 690px) {
			padding-top: 74vh;
			.progress_container {
				margin-top: 0;
			}
			.timer {
				top: 30%;
			}
		}
		@media (max-height: 530px) {
			.timer {
				top: 24%;
			}
			padding-top: 72vh;
			height: 101vh;
			background-size: cover;
			background-repeat: no-repeat;
			.progress_container {
				margin-top: 0;
			}
		}
	`,
);
