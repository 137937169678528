import { useEffect, useState } from 'react';
import { AcademyMatchContainer } from './css';
import AcceptModal from './accept-modal';
import { useGetAvatarsQuery } from 'store/query';
import { useSelector } from 'react-redux';
import { RootState } from 'store/types';
import { useNavigate } from 'react-router-dom';
import { leaveAcademy } from 'store/signalRActions';
import { useAppDispatch } from 'shared/hooks/redux-helper';

const AcademyMatch = () => {
	const { data } = useGetAvatarsQuery();
	const [modal, setModal] = useState(false);
	const [count, setCount] = useState(30);
	const [matchFound, setMatchFound] = useState(false);
	const incomingMessage = useSelector((state: RootState) => state.signalR);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	// const topic = window.location.pathname.split('/').pop();
	useEffect(() => {
		if (incomingMessage && incomingMessage?.data.data !== null && incomingMessage?.data?.event === 'MatchConfirm') {
			setMatchFound(true);
			setModal(true);
		}
	}, [incomingMessage.data.data]);
	useEffect(() => {
		window.Telegram.WebApp.BackButton.onClick(() => {
			dispatch(leaveAcademy());
			navigate('/academy/choose');
		});
		return () => {
			window.Telegram.WebApp.BackButton.onClick(() => {
				navigate('/');
			});
		};
	}, []);
	useEffect(() => {
		if (matchFound) {
			return;
		}
		const timerId = setInterval(() => {
			setCount(prevCount => {
				if (prevCount > 0) {
					return prevCount - 1;
				} else {
					const condition = incomingMessage?.data.event === 'MatchConfirm'; // <--- Your logic here
					if (condition) {
						setMatchFound(true);
						return 0;
					}
					return 30;
				}
			});
		}, 1000);

		return () => {
			clearInterval(timerId);
		};
	}, [matchFound]);

	return (
		<AcademyMatchContainer>
			<img src='/img/academy/second_bg.webp' alt='' className='bg' />
			{/* <p className='topic'>{topic}</p> */}
			<div className='names'>
				<p>{data?.displayName}</p>
				<p>...</p>
			</div>
			<div className='counter'>{count}</div>
			{/* <img src={data?.assets.armor.iconCompleteUrl} alt='' className='figure' /> */}
 			{modal && <AcceptModal status={modal} setStatus={() => setModal(false)} />}
		</AcademyMatchContainer>
	);
};

export default AcademyMatch;
