import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const ExchangeContainer = styled.section(
	({ theme }) => css`
		color: ${theme.colors.text.primary};
		background: #002655;
		padding: 44px 24px 170px;
		display: flex;
		flex-direction: column;
		gap: 8px;
		h1 {
			text-align: center;
			margin-bottom: 22px;
		}
		.green {
			color: #35d899;
		}

		.list_item {
			border-radius: 12px;
			background: #024d95;
			border: 1px solid #f5c21d;
			padding: 8px 24px 8px 16px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.arrow {
				margin-top: 5px;
			}
			.content {
				display: flex;
				gap: 22px;
				align-items: center;
				.icon {
					width: 65px;
					height: 65px;
				}
				.text {
					font-size: 18px;
					font-weight: 500;
					line-height: 22px;
					margin-top: 3px;
				}
			}
		}
	`,
);
