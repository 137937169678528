import { FC, useEffect } from 'react';
import Modal from 'shared/components/modal';
import useCloseModal from 'shared/hooks/use-close-modal';
import { ModalAcceptContainer } from './css';
import { useSelector } from 'react-redux';
import { RootState } from 'store/types';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'shared/hooks/redux-helper';
import { gameAccept, leaveAcademy } from 'store/signalRActions';
import toast from 'react-hot-toast';

const AcceptModal: FC<{ status: boolean; setStatus: (status: boolean) => void }> = ({ status, setStatus }) => {
	const incomingMessage = useSelector((state: RootState) => state.signalR);
	const [_status, onClose] = useCloseModal({ status, setStatus });
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const topic = window.location.pathname.split('/').pop();
	useEffect(() => {
		if (incomingMessage.data.event === 'MatchingRejected') {
			navigate('/academy/choose');
			onClose();
		}
	}, [incomingMessage.data.event]);
	const onCloseClickHandler = () => {
		onClose();
	};
	const handelAccept = async () => {
		const isAccepted = true;
		await dispatch(gameAccept(isAccepted));
		navigate(`/academy/question/${topic}`);
		onClose();
	};

	useEffect(() => {
		window.Telegram.WebApp.BackButton.onClick(() => {
			dispatch(gameAccept(false));
			dispatch(leaveAcademy());
			navigate('/academy/choose');
			toast.error('You declined. Returning to topics…');
		});
		return () => {
			window.Telegram.WebApp.BackButton.onClick(() => {
				navigate('/');
				toast.dismiss();
			});
		};
	}, []);
	return (
		<Modal
			disableHeader={true}
			status={_status}
			onClose={onCloseClickHandler}
			title=''
			useBackgroundForCloseModal={false}
			key={'modal-accept-match'}
			backgroundImage='/img/academy/modal_bg.webp'
		>
			<ModalAcceptContainer>
				<h3>Match Found</h3>

				<div className={'answer_item_correct'} onClick={handelAccept}>
					Accept
				</div>
			</ModalAcceptContainer>
		</Modal>
	);
};

export default AcceptModal;
