import { FC, ReactNode } from 'react';
import { CloseOutline } from '../icons';
import { ModalContainer } from './modal.style';
import { createPortal } from 'react-dom';

type ModalProps = {
	children: ReactNode;
	status: boolean;
	title: string;
	disableHeader?: boolean;
	backgroundColor?: string;
	onClose: () => void;
	isBottomSheet?: boolean;
	useBackgroundForCloseModal?: boolean;
	backgroundImage?: string;
};

const Modal: FC<ModalProps> = ({
	children,
	status,
	onClose,
	title,
	disableHeader,
	backgroundColor,
	isBottomSheet,
	useBackgroundForCloseModal = true,
	backgroundImage,
}) => {
	return createPortal(
		<ModalContainer $isBottomSheet={isBottomSheet} backgroundImage={backgroundImage}>
			<div className={`background ${status ? 'active' : 'deactive'}`} onClick={useBackgroundForCloseModal ? onClose : () => {}}></div>

			<div
				className={`modal_panel ${status ? 'active' : 'deactive'}`}
				style={{
					background: backgroundColor ?? '',
					// backgroundImage: backgroundImage ? `url(${backgroundImage})` : backgroundColor ?? '',
					// backgroundSize: backgroundImage ? 'contain' : '',
					// backgroundPosition: backgroundImage ? 'center' : '',
					// backgroundRepeat: 'no-repeat',
				}}
			>
				{(disableHeader === undefined || disableHeader === false) && (
					<header className='header'>
						<h3>{title}</h3>
						<CloseOutline onClick={onClose} />
					</header>
				)}

				{children}
			</div>
		</ModalContainer>,
		document.body,
		'modal',
	);
};

export default Modal;
