import { CardsContainer } from '../css';
import { SingleCard } from '../components';

import { useGetAssetsQuery } from 'store/query';
import { useSelector } from 'react-redux';
import { RootState } from 'store/types';
import { useEffect } from 'react';

interface ICardsProps {
	category: 'armor' | 'pet';
	onClick: (value: {
		status_: number;
		id: number;
		iconUrl: { pet: string; armor: string };
		price: { diamond?: number; food?: number; prana?: number; education?: number };
	}) => void;
}
const Cards: React.FC<ICardsProps> = ({ category, onClick }) => {
	const { data, refetch, isLoading } = useGetAssetsQuery(category);

	const incomingNotif = useSelector((state: RootState) => state.signalR);
	useEffect(() => {
		if (incomingNotif?.data?.event === 'Notification') {
			refetch();
		}
	}, [incomingNotif, incomingNotif.data.event, incomingNotif.data.data]);

	return (
		<CardsContainer>
			{!isLoading &&
				data?.map(item => (
					<SingleCard
						key={`key-${item.id}`}
						data={item}
						onClick={() => {
							if (onClick && item.status === 1) {
								onClick({
									iconUrl: { pet: item.iconCompleteUrl, armor: item.iconCompleteUrl },
									price: {
										diamond: item.price.diamond,
										food: item.price.food,
										prana: item.price.prana,
										education: item.price.education,
									},
									id: item.id,
									status_: 1,
								});
							}
							if (onClick && item.status === 3) {
								onClick({
									iconUrl: { pet: item.iconCompleteUrl, armor: item.iconCompleteUrl },
									price: {
										diamond: item.price.diamond,
										food: item.price.food,
										prana: item.price.prana,
										education: item.price.education,
									},
									id: item.id,
									status_: 3,
								});
							}
							if (onClick && item.status === 2) {
								onClick({
									iconUrl: { pet: item.iconCompleteUrl, armor: item.iconCompleteUrl },
									price: {
										diamond: item.price.diamond,
										food: item.price.food,
										prana: item.price.prana,
										education: item.price.education,
									},
									id: item.id,
									status_: 2,
								});
							}
							if (onClick && item.status === 4) {
								onClick({
									iconUrl: { pet: item.iconCompleteUrl, armor: item.iconCompleteUrl },
									price: {
										diamond: item.price.diamond,
										food: item.price.food,
										prana: item.price.prana,
										education: item.price.education,
									},
									id: item.id,
									status_: 4,
								});
							}
						}}
					/>
				))}
		</CardsContainer>
	);
};

export default Cards;
