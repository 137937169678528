import { FC } from 'react';
import Button from 'shared/components/button_new';
import Modal from 'shared/components/modal-new';
import useCloseModal from 'shared/hooks/use-close-modal';
import { InfoModalContainer } from '../css';
import { CloseOutline } from 'shared/components/icons';
import { useNavigate } from 'react-router-dom';
interface ISelect {
	pic: string;
	title: string;
	text: string;
	btn: string;
	link: string;
}
const InfoModal: FC<{
	status: boolean;
	setStatus: (status: boolean) => void;
	selectedItem: ISelect;
}> = ({ status, setStatus, selectedItem }) => {
	const [_status, onClose] = useCloseModal({ status, setStatus });
	const navigate = useNavigate();
	const onCheckClickHandler = (link: string) => {
		window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
		navigate(link);
		onClose();
	};

	return (
		<Modal
			backgroundImage='/img/home-avatar/bg-modal.webp'
			disableHeader={true}
			status={_status}
			onClose={onClose}
			title={''}
			isBottomSheet
		>
			<InfoModalContainer>
				<div className='content'>
				<div className='close' onClick={() => onClose()}>
					<CloseOutline />
				</div>
				<img className='header_pic' src={selectedItem.pic} alt='' />
					<h3>{selectedItem.title}</h3>
					<p>{selectedItem.text}</p>

					<Button className='btn' variant='thirty' onClick={() => onCheckClickHandler(selectedItem.link)} id='partner_check'>
						{selectedItem.btn}
					</Button>
				</div>
			</InfoModalContainer>
		</Modal>
	);
};

export default InfoModal;
