import { IGetAllResponse, POSTConnectExchange } from 'api/v1/exchange';
import { FC, useEffect, useRef, useState } from 'react';
import Modal from 'shared/components/modal';
import useCloseModal from 'shared/hooks/use-close-modal';
import { HaveModalContainer } from '../css';
import ButtonNew from 'shared/components/button_new';
import toast from 'react-hot-toast';
import { useAppDispatch } from 'shared/hooks/redux-helper';
import { updateAllExchange } from 'store/query';

const HaveModal: FC<{
	setLoading: (arg: boolean) => void;
	status: boolean;
	data: IGetAllResponse['exchanges'][0];
	setStatus: (status: boolean) => void;
}> = ({ setLoading, data, status, setStatus }) => {
	const [_status, onClose] = useCloseModal({ status, setStatus });
	const [next, setNext] = useState(false);
	const [uid, setUid] = useState<string>();
	const [walletAddress, setWalletAddress] = useState<string>();
	const [isKeyboardOpen, setIsKeyboardOpen] = useState<boolean>(false);
	const dispatch = useAppDispatch();
	const inputRef = useRef<HTMLInputElement>(null);
	useEffect(() => {
		inputRef.current?.focus();
		const handleResize = () => {
			if (window.scrollY > 100) {
				setIsKeyboardOpen(true);
			} else {
				setIsKeyboardOpen(false);
			}
		};

		window.addEventListener('scroll', handleResize);

		return () => {
			window.removeEventListener('scroll', handleResize);
		};
	}, []);

	const handleConnect = () => {
		if (uid && walletAddress) {
			POSTConnectExchange(data.name, uid, walletAddress)
				.then(res => {
					res.isAdded ? toast.success('Added Successfully') : toast.error('error ');
					setLoading(false);
					onClose();
					dispatch(updateAllExchange({ uid, exchange: data.name, walletAddress }));
				})
				.finally(() => window.scroll({ top: 2 }));
		}
	};
	return (
		<Modal isBottomSheet status={_status} onClose={onClose} title={''}>
			<HaveModalContainer style={{ paddingBottom: isKeyboardOpen ? '110px' : '32px' }}>
				<img src={data.iconUrl} alt='' className='head' />
				{next ? <h3>Enter your UID from {data.name}:</h3> : <h3>Do you have a {data.name} account?</h3>}
				{data.name !== 'WEEX' && !next && (
					<ButtonNew className='btn' variant='thirty'>
						<a href={data.link} target='_blank'>
							No, Let’s Create
						</a>
					</ButtonNew>
				)}
				{!next && (
					<>
						<p>To claim your PINEYE tokens, please provide your Deposit Address below.</p>

						<ButtonNew variant='thirty' onClick={() => setNext(true)}>
							Yes, I have an account
						</ButtonNew>
					</>
				)}
				{next && (
					<>
						<input ref={inputRef} placeholder=' ' className='input_' value={uid} onChange={e => setUid(e.target.value)} />
						<h3>Enter your Wallet Address:</h3>
						<input placeholder=' ' className='input_' value={walletAddress} onChange={e => setWalletAddress(e.target.value)} />
						<ButtonNew disabled={uid === undefined || walletAddress === undefined} variant='thirty' onClick={handleConnect}>
							Connect Exchange
						</ButtonNew>
					</>
				)}
			</HaveModalContainer>
		</Modal>
	);
};
export default HaveModal;
