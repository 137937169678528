import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const TabContainer = styled.section(
	() => css`
		border-radius: 18px;
		padding: 1.5px;
		margin-bottom: 20px;

		.border {
			box-shadow: 0px 6px 12px 0px #235370 inset;
			background: #e3f4ff;

			position: relative;
			padding: 8px;
			display: grid;
			grid-template-columns: 1fr 2px 1fr 2px 1fr;
			border-radius: 16px;
			.tab_item {
				padding: 11px 0 9px;
				font-size: 16px;
				font-weight: 400;
				line-height: 19px;
				display: flex;
				align-items: center;
				justify-content: center;

				position: relative;

				&.active {
					background: #f5c907;

					border: 1px solid transparent;

					color: #000;
					border-radius: 14px;
					font-weight: 600;
					padding: 0;
					margin-left: 5px;
					margin-right: 5px;
					padding-top: 3px;
				}
				.remain_social_badge {
					position: absolute;
					right: 0;
					top: 0;
					background-color: #ff0000;
					color: #ffffff;
					border-radius: 50%;
					width: 12px;
					height: 12px;
					padding-top: 1px;
					font-size: 10px;
					font-weight: 600;
					margin-right: 5px;
					margin-top: 2px;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
			.divider {
				width: 2px;
				background-color: #000;
				opacity: 0.2;
				height: 24px;
				margin-top: 10px;
			}
		}
	`,
);
