import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const InfoModalContainer = styled.section(
	() => css`
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 16px;
		position: relative;
		padding: 22px;
		height: 65vh;
		.content {
			.close {
				position: absolute;
				top: 5vh;
				inset-inline-end: -5%;
				background: #cfbe9d;
				border-radius: 100%;
				width: 32px;
				height: 32px;
				display: flex;
				justify-content: center;
				align-items: center;
				border: 1.71px solid #5e2015;
				box-shadow: 0px 1.71px 6.84px 0px #00000070;
				font-weight: bold;
			}
			.header_pic {
				width: 149px;
				height: 149px;
				/* position: absolute; */
				/* top: 1vh; */
				/* left: 0;
				right: 0; */
				margin: 0 auto;
			}
			display: flex;
			flex-direction: column;
			width: 90%;
			position: relative;
			gap: 20px;
			/* bottom: -5vh; */
			h3 {
				font-weight: 600;
				font-size: 24px;
				line-height: 29.71px;
				letter-spacing: 0px;
				text-align: center;
			}
			p {
				font-weight: 400;
				font-size: 16px;
				line-height: 24px;
				letter-spacing: 0px;
				text-align: center;
			}

			.btn {
				width: 100%;
				box-shadow: 0px 3px 4px 0px #0000008c;
				border: 1.5px solid #976d5d;
			}
		}
		@media (max-width: 344px) and (max-height: 884px) {
			.content {
				bottom: -70px;
			}
		}
	`,
);
