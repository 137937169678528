import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { HubConnectionBuilder, HubConnection, LogLevel } from '@microsoft/signalr';
import Telegram from 'shared/utils/telegram';
import store from 'store';
interface IReceiveMessage {
	type: number;
	event: string;
	data: Record<string, unknown>;
	messages: string[];
	gameId?: string;
}

export interface ISignalRState {
	connection: HubConnection | null;
	// any other state you need, e.g., status, errors, etc.
	status: 'idle' | 'connecting' | 'connected' | 'failed';
	error?: string;
	data: IReceiveMessage;
}

const initialState: ISignalRState = {
	connection: null,
	status: 'idle',
	data: {
		gameId: '',
		type: 0,
		event: '',
		data: {},
		messages: [],
	},
};

export const initializeConnection = createAsyncThunk('signalR/initializeConnection', async (hubUrl: string, { rejectWithValue }) => {
	try {
		const tokenData = JSON.parse(localStorage.getItem('access_auth_token') ?? '{}');
		const { userId } = Telegram().getTelegramData();

		const connection = new HubConnectionBuilder()
			.withUrl(hubUrl, {
				headers: {
					Authorization: `Bearer ${tokenData.token}`,
					chatid: `${userId}`.toLowerCase(),
				},
			})
			.withAutomaticReconnect()
			.configureLogging(LogLevel.Information)
			.build();

		await connection.start();

		console.log('SignalR connection started.');

		return connection; // => action.payload in "fulfilled"
	} catch (error) {
		console.error('Error while starting SignalR connection:', error);
		return rejectWithValue(error);
	}
});

const SignalRSlice = createSlice({
	name: 'signalR',
	initialState,
	reducers: {
		addIncomingMessage: (state, action: PayloadAction<IReceiveMessage>) => {
			state.data = action.payload;
		},
		addIncomingNotif: (state, action: PayloadAction<IReceiveMessage>) => {
			state.data = action.payload;
		},
		addNewScore: (state, action: PayloadAction<IReceiveMessage>) => {
			state.data = action.payload;
		},
	},
	extraReducers: builder => {
		builder
			.addCase(initializeConnection.pending, state => {
				state.status = 'connecting';
				state.error = undefined;
			})
			.addCase(initializeConnection.fulfilled, (state, action) => {
				state.status = 'connected';
				state.connection = action.payload; // Store the connection

				// Event items:
				// Authentication
				// Disconnected
				// Connected
				// Validation
				// UnKnown
				// Notify
				// Error
				// Info
				// const ssss = {
				// 	type: 1,
				// 	target: 'ReceiveMessage',
				// 	arguments: [
				// 		{
				// 			event: 'MatchConfirm',
				// 			data: { gameId: '1d5ef0dd-fb91-4c26-90ad-f643f624e9ae', userName: 'Saeed', startTime: 1738834731, endTime: 1738834736 },
				// 			messages: [],
				// 		},
				// 	],
				// };
				state.connection.on('ReceiveMessage', message => {
					store.dispatch(SignalRSlice.actions.addIncomingMessage(message));
					// dispatch some Redux action to store or handle message
					// e.g.: dispatch(addIncomingMessage(message))
				});
				state.connection.on('ReceiveNotification', message => {
					store.dispatch(SignalRSlice.actions.addIncomingNotif(message));
					// dispatch some Redux action to store or handle message
					// e.g.: dispatch(addIncomingMessage(message))
				});
				state.connection.on('ReceiveNewScore', message => {
					store.dispatch(SignalRSlice.actions.addNewScore(message));
					// dispatch some Redux action to store or handle message
					// e.g.: dispatch(addIncomingMessage(message))
				});
			})
			.addCase(initializeConnection.rejected, (state, action) => {
				state.status = 'failed';
				state.error = (action.payload as Error)?.message || 'Failed to connect';
			});
	},
});
export const { addIncomingMessage, addIncomingNotif, addNewScore } = SignalRSlice.actions;

export default SignalRSlice.reducer;
