import { ThemeProvider } from '@emotion/react';
import { LoginButton, TelegramAuthData } from '@telegram-auth/react';
import { GETPendingTaskAPI } from 'api/v1/profile';
import { FC, ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Menu from 'shared/components/menu';
import getDesignToken from 'shared/configs/theme';
import { useAppDispatch, useAppSelector } from 'shared/hooks/redux-helper';
import requestHandler, { ILoginResponse } from 'shared/utils/request-handler';
import { updateAvatars, useGetAvatarsQuery } from 'store/query';
import { changeInitialProfile, changeProfileLogin, changeTasks } from 'store/reducers/profile';
import Splash from '../splash';
import Walkthrough from '../walkthrough/walkthrough';
import { LayoutContainer } from './layout.style';
import { envResolver } from 'shared/configs/env-resolver';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { RootState } from 'store/types';
// import { GETAvatar } from 'api/v1/avatar';

const Layout: FC<{ children: ReactNode }> = ({ children }) => {
	const isLogin = useAppSelector(state => state.profile.isLogin);
	const walkthrough = useAppSelector(state => state.profile.walkthrough);
	const newScore = useSelector((state: RootState) => state.signalR);

	const { data } = useGetAvatarsQuery();
	const originUrl = window.location.origin;

	const navigate = useNavigate();

	const dispatch = useAppDispatch();

	const [isLoading, setIsLoading] = useState<boolean>(true);

	const initData = () => {
		dispatch(updateAvatars({ score: data?.score }));
		GETPendingTaskAPI().then(res => {
			dispatch(changeTasks(res));
		});
	};
	useEffect(() => {
		if (newScore?.data?.event === 'UserScore') {
			const data = newScore.data.data;
			dispatch(
				updateAvatars({
					score: {
						food: data.food as number,
						diamond: data.diamond as number,
						education: data.education as number,
						prana: data.prana as number,
					},
				}),
			);
		}
	}, [newScore, newScore.data.data?.diamond && newScore.data.event]);
	useEffect(() => {
		if (isLogin) {
			dispatch(changeProfileLogin({ isLogin: false, avatar: '', name: '' }));
		}

		if (!originUrl.includes('app2.pineye.io')) {
			requestHandler.loginUser().then(res => {
				if (!window.Telegram?.WebApp?.isExpanded) {
					window.Telegram.WebApp.expand();
					document.body.style.height = 'calc(100% + 2px)';
				}
				document.body.style.height = 'calc(100% + 2px)';

				window.Telegram.WebApp.BackButton.isVisible = true;

				window.Telegram.WebApp.BackButton.onClick(() => {
					navigate('/');
				});

				if (res) {
					const { photo_url, username } = res;
					dispatch(changeProfileLogin({ isLogin: true, avatar: photo_url, name: username }));
					dispatch(changeInitialProfile(res.profile));

					setIsLoading(false);

					initData();
				}
			});
		}
	}, []);

	const onTelegramOauthCallback = (input: TelegramAuthData) => {
		axios
			.get<{ data: ILoginResponse }>('https://api2.pineye.io/api/v1/Oauth/telegram?tgAuthResult=' + btoa(JSON.stringify(input)))
			.then(data => {
				localStorage.setItem('access_auth_token', data.data.data.token);
				dispatch(changeProfileLogin({ isLogin: true, avatar: input.photo_url ?? '', name: input.username ?? '' }));
				setIsLoading(false);
				initData();
			});
	};

	return (
		<ThemeProvider theme={getDesignToken()}>
			<LayoutContainer>
				{isLoading ? (
					<>
						<Splash />
						{originUrl.includes('app2.pineye.io') && (
							<LoginButton
								botUsername={envResolver.MODE === 'PRODUCTION' ? 'rasa_stagebot' : 'rasa_stagebot'}
								buttonSize='large'
								requestAccess={'write'}
								cornerRadius={16}
								onAuthCallback={onTelegramOauthCallback}
							/>
						)}
					</>
				) : !walkthrough ? (
					<Walkthrough />
				) : (
					<>
						<Menu />
						{children}
					</>
				)}
			</LayoutContainer>

			{/* <div className='bottom_right_light'></div> */}
		</ThemeProvider>
	);
};

export default Layout;
