import { GETAllExchange, GetExchange, IGetAllResponse } from 'api/v1/exchange';
import { createCustomApi } from 'store/base.api';
import { AppDispatch } from 'store/types';

const apis = {
	allExchanges: GETAllExchange,
	exchange: GetExchange,
};

const asyncBaseQuery = async ({ path }: { path: keyof typeof apis }) => {
	const data = await apis[path]();
	return { data: data };
};

const ExchangeSliceApi = createCustomApi({
	reducerPath: 'exchange-api',
	baseQuery: asyncBaseQuery,
	endpoints: builder => ({
		allExchanges: builder.query<IGetAllResponse, void>({
			query: () => ({ path: 'allExchanges' }),
		}),

		exchange: builder.query<string, void>({
			query: () => ({ path: 'exchange' }),
		}),
	}),
});

export const updateAllExchange = (data: { uid: string; exchange: string; walletAddress: string }) => (dispatch: AppDispatch) => {
	dispatch(
		ExchangeSliceApi.util.updateQueryData('allExchanges', undefined, draft => {
			(draft.user.uid = data.uid), (draft.user.exchange = data.exchange), (draft.user.walletAddress = data.walletAddress);
		}),
	);
};
export const { useAllExchangesQuery, useExchangeQuery } = ExchangeSliceApi;
export default ExchangeSliceApi;
