import { useEffect, useState } from 'react';
import { ShopPranaContainer } from './css';
import { PracticeCard } from './components';
// import numberUtils from 'shared/utils/number';
// import { useAppSelector } from 'shared/hooks/redux-helper';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { flush, injectGlobal } from '@emotion/css';
import { Overview } from 'pages/home/components';
import { useGetAvatarsQuery } from 'store/query';
import numberUtils from 'shared/utils/number';
import InfoModal from 'pages/home/components/info-modal';

interface ISelect {
	pic: string;
	title: string;
	text: string;
	btn: string;
	link: string;
}
const PranaShop = () => {
	const navigate = useNavigate();
	const { data } = useGetAvatarsQuery();

	const [infoModal, setInfoModal] = useState(false);
	const [selectedItem, setSelectedItem] = useState<ISelect | null>(null);
	useEffect(() => {
		injectGlobal`
			body,nav{
 				background: #00001c;
			}
		`;
		window.Telegram.WebApp.BackButton.onClick(() => {
			navigate('/');
		});
		return () => {
			flush();
			window.Telegram.WebApp.BackButton.onClick(() => {
				navigate('/');
			});
		};
	}, []);

	return (
		<ShopPranaContainer>
			<Helmet>
				<title>PinEye | Prana Shop</title>
			</Helmet>
			<div className='bottom_light' />
			<img src='/img/prana/header.webp' alt='' className='banner' />
			{data && <Overview name={data?.displayName} gem={data.score.diamond} />}
			<div className='games'>
				<div
					className='ed'
					onClick={() => {
						window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
						setInfoModal(true);
						setSelectedItem({
							pic: '/img/home-avatar/academy.webp',
							title: 'Education',
							text: 'Play academy games to earn Education, which you can use to buy clothes, pets, and skills. Customize and enjoy your experience!',
							btn: 'Play Now',
							link: '/academy/choose',
						});
					}}
				>
					<img src='/img/home-avatar/academy.webp' alt='' />
					Education
					<span>{numberUtils.formatPriceWithDecimals(data?.score.education ?? 0)}</span>
				</div>
				<div
					className='prana ed'
					onClick={() => {
						window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
						setInfoModal(true);
						setSelectedItem({
							pic: '/img/home-avatar/prana.webp',
							title: 'Prana',
							text: 'Practice Meditation to earn Prana! Use Prana to unlock new abilities, enhance your experience, and reach a higher state of mindfulness.',
							btn: 'Play Now',
							link: '/prana/shop',
						});
					}}
				>
					<img src='/img/home-avatar/prana.webp' alt='' />
					Prana
					<span>{numberUtils.formatPriceWithDecimals(data?.score.prana ?? 0)}</span>
				</div>
			</div>

			<PracticeCard />
			{infoModal && selectedItem && (
				<InfoModal
					key={`key${selectedItem.text}`}
					selectedItem={selectedItem}
					setStatus={() => setInfoModal(false)}
					status={infoModal}
				/>
			)}
		</ShopPranaContainer>
	);
};
export default PranaShop;
