import { GamesDownContainer } from '../css';
import { useNavigate } from 'react-router-dom';
const GamesDown = () => {
	const navigate = useNavigate();
	return (
		<GamesDownContainer>
			<div className='_container sides' onClick={() => navigate('/academy/choose')}>
				<img src='/img/home-avatar/academy-btn.webp' alt='' />
			</div>
			<div className='_container map' onClick={() => navigate('/map')}>
				<img src='/img/home-avatar/map-btn.webp' alt='' />
			</div>
			<div className='_container sides right'  >
			<img src='/img/home-avatar/fight-btn.webp' alt='' />
			</div>
		</GamesDownContainer>
	);
};
export default GamesDown;
