import { useEffect, useState } from 'react';
import { ExchangeContainer } from './css/exchange.style';
import { ArrowRightOutline, TickBold } from 'shared/components/icons';
import DotLoader from 'shared/components/dot-loader';
import { useNavigate } from 'react-router-dom';
import { useAllExchangesQuery } from 'store/query';
import { HaveModal } from './components';
import { IGetAllResponse } from 'api/v1/exchange';

const Exchange = () => {
	const [modalStatus, setModalStatus] = useState<{ status: boolean }>({ status: false });
	const [selectedData, setSelectedData] = useState<IGetAllResponse['exchanges'][0]>();
	const [chacked, setChecked] = useState('');
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const { data: allExchanges } = useAllExchangesQuery();
	const handleClick = (item: IGetAllResponse['exchanges'][0]) => {
		setSelectedData(item);
		setLoading(true);
		setChecked(item.name);
		setModalStatus({ status: true });
	};
	useEffect(() => {
		window.Telegram.WebApp.BackButton.onClick(() => {
			navigate('/wallet');
		});
		return () => {
			window.Telegram.WebApp.BackButton.onClick(() => {
				navigate('/');
			});
		};
	}, []);
	return (
		<ExchangeContainer>
			<h1>Choose Exchange</h1>

			{allExchanges &&
				allExchanges.exchanges.map((item, index) => {
					return (
						<div className='border' key={`index-${index}`}>
							<div className='list_item' onClick={() => handleClick(item)}>
								<div className='content'>
									<img src={item.iconUrl} className='icon' alt='' />
									<div className='text'>{item.name}</div>
								</div>
								<div className='action'>
									{loading && item.name === chacked ? (
										<DotLoader />
									) : item.name === chacked ? (
										<TickBold className='green' />
									) : (
										<ArrowRightOutline className='arrow' />
									)}
								</div>
							</div>
						</div>
					);
				})}
			{modalStatus.status && selectedData && (
				<HaveModal
					setLoading={setLoading}
					data={selectedData}
					setStatus={status => setModalStatus({ status })}
					status={modalStatus.status}
				/>
			)}
		</ExchangeContainer>
	);
};
export default Exchange;
