import { FC } from 'react';
import numberUtils from 'shared/utils/number';
import { HeaderContainer } from '../css';

interface IPropsHeader {
	name?: string;
	gem: number;
}
const Header: FC<IPropsHeader> = ({ name, gem }) => {
	return (
		<HeaderContainer>
			<div>
				<img src='/img/avatar-shop/head.webp' alt='' />
				<p>{name}</p>
			</div>
			<div>
				<img src='/img/avatar-shop/diamond.webp' alt='' />
				<p>{numberUtils.formatPriceWithDecimals(gem)}</p>
			</div>
		</HeaderContainer>
	);
};

export default Header;
