import { POSTClaimSocialFollower } from 'api/v1/social-follower';
import { FC, useState } from 'react';
import Modal from 'shared/components/modal';
import useCloseModal from 'shared/hooks/use-close-modal';
import numberUtils from 'shared/utils/number';
import { SocialModalContainer } from '../css';
import { ISocialResponse } from 'api/v1/social';
import { TickBold } from 'shared/components/icons';
import { updateSocialClaimById } from 'store/query/social';
import { useAppDispatch } from 'shared/hooks/redux-helper';
import ButtonNew from 'shared/components/button_new';
 
const SocialModal: FC<{
	modal: ISocialResponse['modal'];
	status: boolean;
	title: string;
	reward: number;
	link: string;
	id: number;
	isClaimed: boolean;
	setStatus: (status: boolean) => void;
}> = ({ modal, status, title, reward, link, id, setStatus, isClaimed }) => {
	const [_status, onClose] = useCloseModal({ status, setStatus });
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [canCheck, setCanCheck] = useState<boolean>(false);
	const [loader, setLoader] = useState<boolean>(false);
	const dispatch = useAppDispatch();
	const onCheckClickHandler = () => {
		setIsLoading(true);
		POSTClaimSocialFollower(id)
			.then(() => {
				dispatch(updateSocialClaimById(id));
				window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
 
				onClose();
			})
			.catch(() => {
				setCanCheck(true);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	return (
		<Modal status={_status} onClose={onClose} title={''} isBottomSheet>
			<SocialModalContainer>
				<img className='header_pic' src={modal.modalIconUrl} alt='' />
				<h3>{title}</h3>
				<p>{modal.modalText}</p>
				{isClaimed&&	<ButtonNew
					variant='secondary'
					onClick={() => {
						if (!isClaimed) {
							setLoader(true);
							setTimeout(() => {
								setCanCheck(true);
							}, 500);
							setTimeout(() => {
								setLoader(false);
							}, 2500);
						}
					}}
				>
					<a
						onClick={() => {
							if (!isClaimed) {
								setLoader(true);
								setTimeout(() => {
									setCanCheck(true);
								}, 500);
								setTimeout(() => {
									setLoader(false);
								}, 2500);
							}
						}}
						href={link}
						target='_blank'
						style={{ color: '#000', paddingLeft: '15px',paddingRight:'15px' }}
					>
						{modal.buttonTitle}
					</a>
				</ButtonNew>}
				<div className='reward_container'>
					<img src='/img/avatar-shop/diamond.webp' alt='' className='dollar_img' draggable={false} />
					<div className='value'>+{numberUtils.formatPriceWithDecimals(reward)}</div>
				</div>
				
			{!isClaimed&&	<ButtonNew
					variant='thirty'
					className='btn'
					onClick={() => {
						if (!isClaimed) {
							setLoader(true);
							setTimeout(() => {
								setCanCheck(true);
							}, 500);
							setTimeout(() => {
								setLoader(false);
							}, 2500);
						}
					}}
				>
					<a
						onClick={() => {
							if (!isClaimed) {
								setLoader(true);
								setTimeout(() => {
									setCanCheck(true);
								}, 500);
								setTimeout(() => {
									setLoader(false);
								}, 2500);
							}
						}}
						href={link}
						target='_blank'
						style={{ color: '#000', paddingLeft: '15px',paddingRight:'15px' }}
					>
						{modal.buttonTitle}
					</a>
				</ButtonNew>}
				{(canCheck || isClaimed) && (
					<ButtonNew
						className='btn'
						variant='thirty'
						onClick={onCheckClickHandler}
						isLoading={isLoading}
						disabled={!canCheck || isClaimed}
						id='partner_check'
					>
						{isClaimed ? (
							<>
								Claimed <TickBold className='green' />
							</>
						) : (
							<>
								Check
								{loader && <img src='/img/earn/loading.webp' alt='' className='loader' draggable={false} />}
							</>
						)}
					</ButtonNew>
				)}
			</SocialModalContainer>
		</Modal>
	);
};

export default SocialModal;
