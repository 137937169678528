import { Dispatch, FC, SetStateAction } from 'react';
import { TabContainer } from '../css';

const Tab: FC<{
	remainAcademy: number;
	remaimainSocial: number;
	remainPartners: number;
	activeTab: 'SPECIAL' | 'PARTNERS' | 'ACADEMY';
	setActiveTab: Dispatch<SetStateAction<'SPECIAL' | 'PARTNERS' | 'ACADEMY'>>;
}> = ({ activeTab, setActiveTab, remaimainSocial, remainAcademy, remainPartners }) => {
	return (
		<TabContainer>
			<div className='border'>
			
				<div
					className={`tab_item ${activeTab === 'PARTNERS' ? 'active' : ''}`}
					id='earn_partners_tab'
					onClick={() => {
						if (activeTab !== 'PARTNERS') {
							setActiveTab('PARTNERS');
						}
					}}
				>
					Partners
					{remainPartners > 0 && <div className='remain_social_badge'>{remainPartners}</div>}
				</div>
				<div className='divider' />
				<div
					className={`tab_item ${activeTab === 'ACADEMY' ? 'active' : ''}`}
					id='earn_academy_tab'
					onClick={() => {
						if (activeTab !== 'ACADEMY') {
							setActiveTab('ACADEMY');
						}
					}}
				>
					Academy
					{remainAcademy > 0 && <div className='remain_social_badge'>{remainAcademy}</div>}
				</div>
				<div className='divider' />
				<div
					className={`tab_item ${activeTab === 'SPECIAL' ? 'active' : ''}`}
					id={'earn_special_tab'}
					onClick={() => {
						if (activeTab !== 'SPECIAL') {
							setActiveTab('SPECIAL');
						}
					}}
				>
					Special
					{remaimainSocial > 0 && <div className='remain_social_badge'>{remaimainSocial}</div>}
				</div>
			</div>
		</TabContainer>
	);
};

export default Tab;
