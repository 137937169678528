import { useEffect } from 'react';
import Helmet from 'react-helmet';

import { useGetAvatarsQuery } from 'store/query';
import { Games, GamesDown, Overview, Pineye } from './components';
import { HomeContainer } from './css';
const Home = () => {
	const { data } = useGetAvatarsQuery();

	useEffect(() => {
		window.Telegram.WebApp.BackButton.isVisible = false;

		return () => {
			window.Telegram.WebApp.BackButton.isVisible = true;
		};
	}, []);

	useEffect(() => {
		window.scroll({ top: 2 });
		const touchCancel = (e: TouchEvent) => {
			e.preventDefault();
		};
		document.addEventListener('touchstart', touchCancel);
		return () => {
			document.removeEventListener('touchstart', touchCancel);
		};
	}, []);

	return (
		<HomeContainer>
			<Helmet>
				<title>PinEye | home</title>
			</Helmet>

			<img src={`/img/home-avatar/base.webp`} alt='' className='base_img' />
			<img src={`/img/home-avatar/sky.webp`} alt='' className='rotate_img' />

			{data && <Overview name={data?.displayName} gem={data.score.diamond ?? 0} />}
			<Games />
			{data?.assets.armor && <Pineye pet={data?.assets?.pet?.iconCompleteUrl} img={data?.assets.armor?.iconCompleteUrl} />}
			<GamesDown />
		</HomeContainer>
	);
};

export default Home;
