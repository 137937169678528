import { FC } from 'react';
import Modal from 'shared/components/modal';
import useCloseModal from 'shared/hooks/use-close-modal';
import ButtonNew from 'shared/components/button_new';
import { useNavigate } from 'react-router-dom';
import { GameModalContainer } from '../css';
import { CloseOutline } from 'shared/components/icons';

const GameModal: FC<{ status: boolean; setStatus: (status: boolean) => void; type: 'Prana' | 'Academy' }> = ({
	status,
	setStatus,
	type,
}) => {
	const [_status, onClose] = useCloseModal({ status, setStatus });
	const navigate = useNavigate();

	return (
		<Modal
			disableHeader={true}
			status={_status}
			onClose={onClose}
			title=''
			useBackgroundForCloseModal={true}
			key={'modal-result-match'}
			backgroundImage='/img/academy/modal1_bg.webp'
		>
			{type && (
				<GameModalContainer>
					<img src={type === 'Prana' ? '/img/home-avatar/prana.webp' : '/img/home-avatar/academy.webp'} className='header_img' />
					<div className='close' onClick={() => onClose()}>
						<CloseOutline />
					</div>
					<h2>{type}</h2>
					<p>
						{type === 'Prana'
							? 'The Prana Chamber is a sacred space for meditation, helping users enhance their prana energy and earn Education, Prana, Food, and Diamonds as rewards.'
							: 'Challenge your knowledge on topics you love, compete with others, and upgrade your Education while enjoying a fun, interactive learning experience!'}
					</p>
					<ButtonNew
						variant='primary'
						className='play'
						onClick={(e) => {
							e.stopPropagation()
							navigate(type === 'Prana' ? '/prana/shop' : ('/academy/choose' as string))}}
					>
						Play Now
					</ButtonNew>
				</GameModalContainer>
			)}
		</Modal>
	);
};

export default GameModal;
