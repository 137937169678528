import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const InfoContainer = styled.div(
	() => css`
		border: 1px solid #f5c21d;
		box-shadow: 1px -7px 4px 0px #000000bf;
		background: #0d5aa4;
		border-radius: 10px;
		color: #fff;

		padding-top: 0;
		.name {
			background: #00224d;
			padding: 8px;
			border-radius: 10px;
			text-align: center;
			font-size: 18px;
			line-height: 26px;
			font-weight: bold;
		}
		.detail {
			display: grid;
			grid-template-columns: 78px auto;
			gap: 13px;
			align-items: flex-start;
			padding: 16px;
			img {
				width: 74px;
				height: 74px;
				border-radius: 100%;
				border: 3px solid #a6fed7;
			}
			.wallet_info {
				display: flex;
				flex-direction: column;
				gap: 4px;
				.container {
					display: grid;
					grid-template-columns: 1fr 1fr;
					gap: 4px;
					.exchange {
						font-weight: bold;
						font-size: 18px;
						line-height: 26px;
						display: flex;

						flex-direction: column;

						.box {
							background: #2972b8;
							border: 1px solid #034380;
							display: flex;
							gap: 7px;
							padding: 4px 8px;
							border-radius: 10px;
							img {
								width: 26px;
								height: 26px;
							}
                            span{
                                color: #F5C21D;
                            }
						}
					}
				}
				.wallet_address {
					display: flex;
					flex-direction: column;
					font-weight: bold;
					font-size: 18px;
					line-height: 26px;
                    .box {
							background: #2972b8;
							border: 1px solid #034380;
							display: flex;
							gap: 7px;
							padding: 4px 8px;
							border-radius: 10px;
						 
						}
				}
			}
		}
	`,
);
