import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import 'shared/css/reset.css';
import store from 'store/index';
import App from './app';

ReactDOM.createRoot(document.getElementById('root')!).render(
	<Provider store={store}>
		<App />
	</Provider>,
);
