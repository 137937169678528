import { useGetAvatarsQuery } from 'store/query';
import { GamesContainer } from '../css';
import numberUtils from 'shared/utils/number';
import { useState } from 'react';
import InfoModal from 'pages/home/components/info-modal'

interface ISelect {
	pic: string;
	title: string;
	text: string;
	btn: string;
	link: string;
}
const Games = () => {
		const {data:userData} =useGetAvatarsQuery()
 		const [infoModal, setInfoModal] = useState(false);
		const [selectedItem, setSelectedItem] = useState<ISelect | null>(null);
		const data = [
			{
				title: 'Education',
				cost: userData?.score.education ?? 0,
				id: 'Education',
				pic: '/img/home-avatar/academy.webp',
	
				onclick: () => {
					window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
					setInfoModal(true);
					setSelectedItem({
						pic: '/img/home-avatar/academy.webp',
						title: 'Education',
						text: 'Play academy games to earn Education, which you can use to buy clothes, pets, and skills. Customize and enjoy your experience!',
						btn: 'Play Now',
						link: '/academy/choose',
					});
				},
				background: 'default',
			},
			{
				title: 'Prana',
				id: 'Prana',
				cost: userData?.score.prana ?? 0,
				pic: '/img/home-avatar/prana.webp',
				onclick: () => {
					window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
					setInfoModal(true);
					setSelectedItem({
						pic: '/img/home-avatar/prana.webp', 
						title: 'Prana',
						text: 'Practice Meditation to earn Prana! Use Prana to unlock new abilities, enhance your experience, and reach a higher state of mindfulness.',
						btn: 'Play Now',
						link: '/prana/shop',
					});
				},
				background: 'default',
			},
	
			{
				title: 'Food',
				id: 'Food',
				cost: userData?.score.food ?? 0,
				pic: '/img/home-avatar/food.webp',
				onclick: () => {
					window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
					setInfoModal(true);
					setSelectedItem({
						pic: '/img/home-avatar/food.webp',
						title: 'Food',
						text: 'Play the Farm game to earn Food and Diamonds! Use Food to buy your pets and unlock new experiences. Farm game is coming soon ...',
						btn: 'Back to Home ',
						link: '/',
					});
				},
				background: 'default',
			},
		];

	return (
		<GamesContainer>
			{data.map((item, index) => (
				<div 	onClick={item.onclick} className={`div${index + 1} border`} id={item.id} key={`${index}-${item.title}`}>
					<div className={`main  `}>
						<img src={item.pic} alt='' />
						<p>{item.title}</p>
						<span>{numberUtils.formatPriceWithDecimals(item.cost)}</span>
					</div>
				</div>
			))}
				{infoModal && selectedItem && (
				<InfoModal
					key={`key${selectedItem.text}`}
					selectedItem={selectedItem}
					setStatus={() => setInfoModal(false)}
					status={infoModal}
				/>
			)}
		</GamesContainer>
	);
};
export default Games;
