import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const BuyContainer = styled.section(
	() => css`
		display: grid;
		grid-template-columns: 110px 110px;
		justify-content:space-between;
		align-items: center;
		color: #fff;
		position: fixed;
		top: 40vh;
		width: 100%;
		padding: 0 16px;
		inset-inline-start:10%;
		transform: translateX(-10%);
		z-index: 3;
		.cost {
			background: #041e39;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 8px 5px;
			border-radius: 8px;
			gap: 4px;
			font-size: 11px;
			font-weight: 600;
			line-height: 14px;
			text-align: center;
			z-index: 3;
			line-height: 23px;

			span {
				color: #f5c11d;
			}
		}
		.buy {
			font-size: 14px;
 			line-height: 38px;
			text-align: center;
			z-index: 3;
			font-weight: bolder;
			color: #fff;
		}
		@media (max-width: 350px) {
			top: 39vh;
		}
	`,
);
