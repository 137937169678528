import { FC } from 'react';
import Modal from 'shared/components/modal';
import { CloseOutline } from 'shared/components/icons';
import useCloseModal from 'shared/hooks/use-close-modal';
import { ImageModalContainer } from '../css';

const ImageModal: FC<{ status: boolean; setStatus: (status: boolean) => void }> = ({ status, setStatus }) => {
	const [_status, onClose] = useCloseModal({ status, setStatus });
	return (
		<Modal
			disableHeader={true}
			status={_status}
			onClose={onClose}
			title=''
			useBackgroundForCloseModal={true}
			key={'modal-result-image'}
		>
			<ImageModalContainer>
				<div onClick={onClose} className='close'>
					<CloseOutline />
				</div>
				<img src='/img/map/si-big.webp' alt='' />
			</ImageModalContainer>
		</Modal>
	);
};
export default ImageModal;
