import { CharacterContainer } from '../css';

interface ICharacterProps {
	imageUrlPet?: string;
	imageUrlChar?: string;
}

const Character: React.FC<ICharacterProps> = ({ imageUrlPet, imageUrlChar }) => {

	return (
		<CharacterContainer>
			<img src={imageUrlChar} alt='Selected Character' className={imageUrlPet === undefined ? 'char' : 'char_with_pet'} />
			{imageUrlPet && imageUrlPet?.length > 0 && <img src={imageUrlPet} alt='Selected pet' className='pet' />}
		</CharacterContainer>
	);
};

export default Character;
