import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const GameModalContainer = styled.section(
	() => css`
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-content: center;
		align-items: center;
		gap: 8px;
		height: 280px;
		position: relative;
		padding-top: 30px;
		.header_img {
			position: absolute;
			top: -40px;
			inset-inline-start: 42%;
			width: 112px;
			height: 103px;
			padding: 0;
		}
        .close{
            position: absolute;
			top: 4vh;
			inset-inline-end: 10%;
			background:#CFBE9D;
			border-radius: 100%;
			width:32px;
			height: 32px;
			display: flex;
			justify-content: center;
			align-items: center;
			border: 1.71px solid #5E2015;
			box-shadow: 0px 1.71px 6.84px 0px #00000070;
			font-weight: bold;
        }
		h2 {
			position: relative;
			top: -11px;
			margin-inline-start: 10%;
			font-weight: bold;
			font-size: 17.98px;
			line-height: 22.26px;
			letter-spacing: 8%;
            color:#003D66;
		}
		p {
			position: relative;
			top: -10px;
			width: 70%;
			margin-inline-start: 10%;
			text-align: justify;
			font-weight: 400;
			font-size: 12.59px;
			line-height: 19.78px;
			letter-spacing: 0px;
			text-align: center;
		}
        button{
            width: 70%;
            margin-inline-start: 10%;
            height: 42px;
            box-shadow: 0px 3px 4px 0px #0000008C;
            color:#fff;
        }
	`,
);
