import { FC } from 'react';
import Modal from 'shared/components/modal';
import { InfoModalContainer } from '../css';
import useCloseModal from 'shared/hooks/use-close-modal';
import { useNavigate } from 'react-router-dom';
import ButtonNew from 'shared/components/button_new';

interface IInfoMoalProps {
	status: boolean;
	setStatus: (status: boolean) => void;
}

const InfoModal: FC<IInfoMoalProps> = ({ status, setStatus }) => {
	const [_status, onClose] = useCloseModal({ status, setStatus });
	const navigate = useNavigate();
	return (
		<Modal isBottomSheet status={_status} onClose={onClose} title={''}>
			<InfoModalContainer>
				<div className='content'>
					<h3>Do you Want to Quit?</h3>
					<p>
						If you quit now, you will lose the current game and all the progress you have made. Are you sure you want to quit?
					</p>
					<div className='btns'>
						<ButtonNew
							className='yes'
							variant='primary'
							onClick={() => {
								navigate('/prana/shop');

								onClose();
							}}
						>
							Yes
						</ButtonNew>
						<ButtonNew
							className='no'
							variant='thirty'
							onClick={() => {
								onClose();
							}}
						>
							No
						</ButtonNew>
					</div>
				</div>
			</InfoModalContainer>
		</Modal>
	);
};

export default InfoModal;
