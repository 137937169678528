import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const NotEligibleContainer = styled.div(
	() => css`
		border: 1px solid #f5c21d;
		background: #024d95;
		border-radius: 10px;
		display: flex;
		padding: 18px;
		gap: 18px;
		align-items: center;
		justify-content: center;
		.warning {
			width: 73px;
			height: 73px;
		}
		.divider {
			width: 2px;
			height: 90px;
			background: linear-gradient(0deg, transparent 0%, #f9f9f9 50%, transparent 100%);
			margin: 10px 0;
		}
		.content_ {
			font-size: 14px;
			font-weight: bold;
			line-height: 21px;
			color: #fff;
			display: flex;
			flex-direction: column;
			text-align: left;
			gap: 8px;
		}
		.box_ {
			background: #5a99ff;
			box-shadow: 0px 2px 4px 0px #00000073 inset;
			border-radius: 3px;
			margin-top: 4px;
			color: #000;
			padding: 10px 8px 6px;
			text-align: center;
		}
	`,
);
