import { FC } from 'react';
import { ButtonContainer } from './button.style';
import DotLoader from '../dot-loader';

const ButtonNew: FC<
	{
		variant: 'primary' | 'secondary' | 'thirty';
		isLoading?: boolean;
	} & React.ComponentProps<'button'>
> = ({ children, variant, className, isLoading, disabled, onClick }) => {
	return (
		<ButtonContainer
			className={`${variant} ${className ?? ''} ${disabled ? 'disabled' : ''}`}
			disabled={disabled !== undefined ? disabled : isLoading === true ? true : undefined}
			onClick={onClick}
		>
			{isLoading ? <DotLoader /> : children}
		</ButtonContainer>
	);
};

export default ButtonNew;
