import { css } from '@emotion/react';
import styled from '@emotion/styled';

interface IProps {
	colorCode: string;
}
export const GamesContainer = styled.section<IProps>(
	({ theme, colorCode }) => css`
		color: ${theme.colors.text.primary};
		display: grid;
		padding-inline-start:16px;
		padding-inline-end:16px;
 		grid-template-columns: 1fr 3fr 1fr;
		grid-template-rows: repeat(2, 1fr);
 		grid-row-gap: 25px;
		z-index: 3;
		position: fixed;
		top: 75px;
		width: 100%;
		.div1 {
			grid-area: 1 / 1 / 2 / 2;
		}
		.div2 {
			grid-area: 1 / 3 / 2 / 4;
		}
		.div3 {
			grid-area: 2 / 1 / 3 / 2;
		}
		.div4 {
			grid-area: 2 / 3 / 3 / 4;
		}
		.div5 {
			grid-area: 1 / 2 / 3 / 3;
		}
		justify-items: center;
		justify-content: center;
		margin-bottom: 8px;

		.main {
			span {
				font-weight: bold;
				font-size: 17px;
				line-height: 21.05px;
				letter-spacing: 0px;
				text-align: center;
			}
			img {
				position: absolute;
				top: -30%;
				inset-inline-start: 13%;
				width: 54px;
				height: 54px;
			}
			font-weight: bold;
			font-size: 11px;
			line-height: 13.62px;
			letter-spacing: 0px;
			text-align: center;
			gap: 4px;
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: 70px;
			height: 75px;
			padding: 18px 9px 0px 9px;
			border-radius: 13px;
			text-wrap-mode:nowrap;
			background: linear-gradient(180deg, #3cb1fa 30%, #045587 77%);
		}
		.black {
			background: ${colorCode};
		}
 
	`,
);
