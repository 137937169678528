import { GETClaimPractice, GETPracticeDetails, IGetClaimPractice, IGetPracticeDetails } from 'api/v1/practice';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { CongratModal, InfoModal, SelectLangModal, Timer, TimerFixed } from './components';
import { PracticeContainer } from './css';
import { useGetAllPracticeQuery } from 'store/query';

const Practice = () => {
	const [start, setStart] = useState(false);
	const [warningModal, setWarningModal] = useState(false);
	const [end, setEnd] = useState(false);
	const [letStart, setLetStart] = useState(false);
	const [count, setCount] = useState<number | null>(null);
	const [sound, setSound] = useState(true);
	const [data, setData] = useState<IGetPracticeDetails | null>(null);
	const navigate = useNavigate();
	const audioRef = useRef(null);
	const videoRef = useRef<HTMLVideoElement>(null);
	const [selectedLang, setSelectedLang] = useState<'arabic' | 'english' | 'french' | 'indonesian' | 'persian' | 'russian'>('english');
	const [selectLangModal, setSelectLangModal] = useState(true);
	const [congratsModal, setCongratsModal] = useState(false);
	const [congratsContent, setCongratsContent] = useState<IGetClaimPractice['modal'] | null>(null);

	const id = window.location.pathname.split('/').pop();
	useEffect(() => {
		if (id) {
			GETPracticeDetails(+id).then(res => setData(res));
		}
	}, []);
	const { refetch } = useGetAllPracticeQuery();

	useEffect(() => {
		if (sound && start && !end && audioRef.current) {
			(audioRef.current as HTMLAudioElement).play();
		} else if (!sound && audioRef.current) {
			(audioRef.current as HTMLAudioElement).pause();
			// (audioRef.current as HTMLAudioElement).currentTime = ;
		} else if (!start && end && audioRef.current) {
			(audioRef.current as HTMLAudioElement).pause();
			// (audioRef.current as HTMLAudioElement).currentTime = 0;
		}
	}, [sound, start, end]);
	useEffect(() => {
		if (start && !end && videoRef.current) {
			videoRef.current.play();
		} else if (videoRef.current) {
			videoRef.current.pause();
		}
	}, [start, sound, end]);
	useEffect(() => {
		window.Telegram.WebApp.BackButton.onClick(() => {
			navigate('/prana/shop');
		});
		return () => {
			window.Telegram.WebApp.BackButton.onClick(() => {
				navigate('/');
			});
		};
	}, []);

	const handleClose = () => {
		if (end) {
			navigate('/prana/shop');
		} else {
			setWarningModal(true);
		}
	};

	useEffect(() => {
		if (letStart) {
			let n = 3;
			const interval = setInterval(() => {
				if (n > 0) {
					setCount(n);
					n--;
				} else {
					clearInterval(interval);
					setLetStart(false);
					setStart(true);
					setCount(null);
				}
			}, 1000);
		}
		return () => {};
	}, [letStart]);
	useEffect(() => {
		if (end && id) {
			GETClaimPractice(+id)
				.then(res => {
					refetch();
					setCongratsModal(true);
					setCongratsContent(res.modal);
				})
				.then(() => {
					setEnd(false);
					setStart(false);
				})
				.catch(() => {
					setEnd(false);
					setStart(false);
					navigate('/prana/shop');
				});
		}
	}, [end]);
	return (
		<PracticeContainer>
			<Helmet>
				<title>PinEye | prana Practice</title>
			</Helmet>
			<audio ref={audioRef} src={data?.voices[selectedLang]} loop style={{ display: 'none' }} />

			{data && <img className='background' src={data.backgroundImage} alt='' />}
			{letStart && (
				<div className='counter_container'>
					<div className='lets_start'>{count}</div>
				</div>
			)}
			<div className='man_container'>
				<img className='man' src='/img/prana/man.webp' alt='' />
				<div className='head'>
					<div className='close' onClick={() => handleClose()}>
						<img src={'/img/prana/close.webp'} alt='' />
					</div>
					<div className='sound' onClick={() => setSound(!sound)}>
						{sound ? <img src='/img/game/sound.webp' alt='' /> : <img src='/img/game/sound-off.webp' alt='' />}
					</div>
				</div>

				{data && (
					<video
						ref={videoRef}
						src={data.videoUrl}
						className='infinite'
						loop={true}
						playsInline
						muted={true}
						preload='auto'
						style={{ display: 'block' }}
					>
						<source src={data.videoUrl} type='video/webm' />
					</video>
				)}
			</div>
			<p className='text'>
				Close your Eyes and
				<br />
				Listen to the <strong>Voice</strong>
			</p>
			{data && (
				<div className='timer'>
					{!start && !end && <TimerFixed date={data.practiceTime * 1000} />}
					{start && (
						<Timer
							setEnd={() => {
								setEnd(true);
								setStart(false);
							}}
							date={data.practiceTime * 1000}
						/>
					)}
				</div>
			)}

			{warningModal && <InfoModal status={warningModal} setStatus={setWarningModal} />}
			{selectLangModal && (
				<SelectLangModal
					selectedLang={selectedLang}
					setSelectedLang={setSelectedLang}
					status={selectLangModal}
					setStatus={setSelectLangModal}
					handleStart={() => {
						setLetStart(true);
						setTimeout(() => {
							setStart(true);
						}, 4000);
					}}
				/>
			)}
			{congratsModal && congratsContent && (
				<CongratModal congratsContent={congratsContent} status={congratsModal} setStatus={setCongratsModal} />
			)}
		</PracticeContainer>
	);
};
export default Practice;
