import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const ButtonContainer = styled.button(
	({ theme }) => css`
		color: ${theme.colors.text.primary};
		appearance: none;
		border: none;
		font-weight: 600;
		font-size: 16px;
		line-height: 48px;
		color: #000;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 14px;
		position: relative;
		border: 1px solid #8D604E;
		&:disabled {
			background: #969696 !important;
			border: 1px solid #696665; 
			cursor: not-allowed;
		}
		.node {
			position: relative;
			top: 8px;
		}
		&.secondary {
			background: #2BD8FF;
		}
		&.primary {
			background: #3DB716;
		}
		&.thirty {
			background: #fcc00f;
		}
	`,
);
