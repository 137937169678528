import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const MapContainer = styled.div(
	() => css`
	 
		.stupid_img {
			img {
				width: 171px;
				height: 108px;
			}
 			position: fixed;
			bottom: 14%;
			inset-inline-start: 16px;
		}
		 
	`,
);
