import { FC } from 'react';
import Modal from 'shared/components/modal';
import useCloseModal from 'shared/hooks/use-close-modal';
import { ModalResultContainer } from './css';
import ButtonNew from 'shared/components/button_new';
import { useNavigate } from 'react-router-dom'; 
import { IPlayer } from 'store/types';

const ResultModal: FC<{ isLeft: boolean; status: boolean; setStatus: (status: boolean) => void; me: IPlayer }> = ({
	isLeft,
	status,
	setStatus,
	me,
}) => {
	const [_status, onClose] = useCloseModal({ status, setStatus });
 	const navigate = useNavigate();
	// const { data } = useGetAvatarsQuery();

	const onCloseClickHandler = () => {
	 
		onClose();
		navigate('/');
	};
	const onPlayClickHandler = () => {
	 
		onClose();
		navigate('/academy/choose');
	};

	return (
		<Modal
			disableHeader={true}
			status={_status}
			onClose={onCloseClickHandler}
			title=''
			useBackgroundForCloseModal={false}
			key={'modal-result-match'}
			backgroundImage='/img/academy/modal1_bg.webp'
		>
			{me && (
				<ModalResultContainer>
					<img src={me.isWinner ? '/img/academy/gold.webp' : '/img/academy/silver.webp'} className='stars' alt='' />
					<h2 className={isLeft ? 'is_left' : ''}>
						{me?.isWinner ? 'You Win' : isLeft ? 'Opponent Left, Game Over!' : 'You Lose'}
					</h2>
					<div className='reward_box'>
						<span>Your Rewards</span>
						<div className='gem'>
							<div>🎓 +{me?.score.education}</div> <div>💎 +{me.score.diamond}</div>
						</div>
					</div>
					<div className='btns'>
						<ButtonNew variant='thirty' onClick={onCloseClickHandler}>
							Home
						</ButtonNew>
						<ButtonNew variant='primary' className='play' onClick={onPlayClickHandler}>
							Play
						</ButtonNew>
					</div>
				</ModalResultContainer>
			)}
		</Modal>
	);
};

export default ResultModal;
