import { FC, useState } from 'react';
import { OverviewContainer } from '../css';
import numberUtils from 'shared/utils/number';
import InfoModal from './info-modal';

interface IPropsOverview {
	name: string;
	gem: number;
}

interface ISelect {
	pic: string;
	title: string;
	text: string;
	btn: string;
	link: string;
}
const Overview: FC<IPropsOverview> = ({ name, gem }) => {
	const [infoModal, setInfoModal] = useState(false);
	const [selectedItem, setSelectedItem] = useState<ISelect | null>(null);
	return (
		<OverviewContainer>
			<div>
				<img src='/img/avatar-shop/head.webp' alt='' />
				<p>{name}</p>
			</div>
			<div
				onClick={() => {
					window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
					setInfoModal(true);
					setSelectedItem({
						pic: '/img/home-avatar/diamond.webp',
						title: 'Diamond',
						text: 'Use Diamonds to buy skills! Plus, at the end of Season 02, you`ll earn Tokens through your Diamonds. Skills and Fight game coming soon ...',
						btn: 'Back to Home ',
						link: '/',
					});
				}}
			>
				<img src='/img/avatar-shop/diamond.webp' alt='' />
				<p>{numberUtils.formatPriceWithDecimals(gem)}</p>
			</div>
			{infoModal && selectedItem && (
				<InfoModal
					key={`key${selectedItem.text}-120`}
					selectedItem={selectedItem}
					setStatus={() => setInfoModal(false)}
					status={infoModal}
				/>
			)}
		</OverviewContainer>
	);
};
export default Overview;
