import { MapContainer } from './css';
import React, { useEffect, useRef, useState } from 'react';
import Phaser from 'phaser';
import { useGetAvatarsQuery } from 'store/query';
import { Games, Header } from './components';
import GameModal from './components/game-modal';
import ImageModal from './components/image-modal';
interface ObjectTile {
	id: number;
	image: string;
}

class MapClass extends Phaser.Scene {
	controls!: Phaser.Cameras.Controls.SmoothedKeyControl;
	objectSet: ObjectTile[] = [];
	map: Phaser.Tilemaps.Tilemap | undefined;
	async preload() {
		this.load.setBaseURL('/tile-map');
		this.load.image('tiles', 'tileset200x200.png');
		// this.load.image('image1_1', 'temple1.webp');
		this.load.tilemapTiledJSON('map', 'map.json');
		this.load.image('lake', '/objects/lake.webp');
		this.load.image('road', '/objects/road.webp');
	}

	create() {
		this.map = this.add.tilemap('map');
		const tileset1 = this.map.addTilesetImage('tileset', 'tiles') ?? ({} as Phaser.Tilemaps.Tileset);
		// Create Tile Layer 1 and set its depth to 0
		const tileLayer1 = this.map.createLayer('Tile Layer 1', [tileset1], 200, 200);
		tileLayer1?.setDepth(0);

		// ---- Draw Grid Overlay between Layer 1 and Layer 2 ----
		const gridGraphics = this.add.graphics();
		gridGraphics.lineStyle(1, 0xf1f1f1, 0.1); // Adjust line thickness, color, and alpha as needed

		const tileW = this.map.tileWidth; // e.g. 200
		const tileH = this.map.tileHeight; // e.g. 100
		const offsetX = 200; // same offset used when creating layers
		const offsetY = 200;

		for (let col = 0; col < this.map.width; col++) {
			for (let row = 0; row < this.map.height; row++) {
				// Calculate the center of the isometric tile
				const centerX = offsetX + (col - row) * (tileW / 2) + tileW / 2;
				const centerY = offsetY + (col + row) * (tileH / 2) + tileH / 2;

				// Determine the diamond corners
				const top = new Phaser.Math.Vector2(centerX, centerY - tileH / 2);
				const right = new Phaser.Math.Vector2(centerX + tileW / 2, centerY);
				const bottom = new Phaser.Math.Vector2(centerX, centerY + tileH / 2);
				const left = new Phaser.Math.Vector2(centerX - tileW / 2, centerY);

				// Draw the diamond (grid cell)
				gridGraphics.strokePoints([top, right, bottom, left, top], true);
			}
		}
		// Set gridGraphics depth to 1 so it's above Layer 1 but under Layer 2
		gridGraphics.setDepth(1);
		// --------------------------------------------------------

		// Create Tile Layer 2 and set its depth higher than gridGraphics
		const tileLayer2 = this.map.createLayer('Tile Layer 2', [tileset1], 200, 200);
		tileLayer2?.setDepth(2);
		tileLayer2?.setInteractive();
		tileLayer2?.on('pointerdown', (pointer: Phaser.Input.Pointer) => {
			const tile = tileLayer2.getTileAtWorldXY(pointer.worldX, pointer.worldY);
			if (tile) {
				if (tile.index === 44 || tile.index === 43) {
					window.dispatchEvent(new CustomEvent('tileClicked', { detail: tile }));
				}
			}
		});

		// Create the "Can Plant" layer above layer2
		const canPlantLayer = this.map.createLayer('Can Plant', [tileset1], 200, 200);
		canPlantLayer?.setDepth(3);

		// Set camera bounds so it doesn't move outside the map and center it
		const mapWidth = this.map.widthInPixels;
		const mapHeight = this.map.heightInPixels;
		this.cameras.main.setBounds(0, 0, mapWidth, mapHeight);
		this.cameras.main.centerOn(mapWidth / 20, mapHeight / 2);

		this.add
			.image(mapWidth * 0.1, mapHeight, 'lake')
			.setOrigin(0.5, 0.5)
			.setDepth(2);
		this.add
			.image(mapWidth * 0.09, mapHeight * 0.63, 'road')
			.setOrigin(0.5, 0.5)
			.setData(2);
		// Replace keyboard controls with touch controls for Telegram Mini App
		this.input.addPointer(1);
		let touchX = 0;
		let touchY = 0;
		this.input.on('pointerdown', (pointer: Phaser.Input.Pointer) => {
			touchX = pointer.x;
			touchY = pointer.y;
		});
		this.input.on('pointermove', (pointer: Phaser.Input.Pointer) => {
			const deltaX = pointer.x - touchX;
			const deltaY = pointer.y - touchY;
			this.cameras.main.scrollX -= deltaX * 0.5;
			this.cameras.main.scrollY -= deltaY * 0.5;
			touchX = pointer.x;
			touchY = pointer.y;
		});
	}
	update(_: number, delta: number) {
		if (this.controls) {
			this.controls.update(delta);
		}
	}
}
const MapPage: React.FC = () => {
	const phaserRef = useRef<HTMLDivElement>(null);
	const { data } = useGetAvatarsQuery();
	const [modal, setModal] = useState(false);
	const [big, setBig] = useState(false);

	interface Tile {
		index: number;
	}

	const [selectedTile, setSelectedTile] = useState<Tile | null>(null);

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const handleTileClick = (event: any) => {
			setSelectedTile(event.detail);
			setModal(true);
		};

		window.addEventListener('tileClicked', handleTileClick);
		return () => {
			window.removeEventListener('tileClicked', handleTileClick);
		};
	}, []);
	useEffect(() => {
		const config: Phaser.Types.Core.GameConfig = {
			type: Phaser.WEBGL,
			width: '100%',
			height: '100%',
			backgroundColor: '#2d2d2d',
			parent: phaserRef.current ?? undefined,
			scene: MapClass,
			pixelArt: true,
		};

		const game = new Phaser.Game(config);
		return () => game.destroy(true);
	}, []);

	return (
		<MapContainer ref={phaserRef} style={{ width: '100%', height: '100%' }} >
			{data  && <Header name={data.displayName} gem={data.score.diamond} />}
			{data  && <Games />}
			{!big && (
				<div className='stupid_img' onClick={() => setBig(true)}>
					<img src='/img/map/si.webp' alt='' />
				</div>
			)}

			{modal && selectedTile && (
				<GameModal
					type={selectedTile.index === 43 ? 'Prana' : 'Academy'}
					status={modal}
					setStatus={() => {
						setModal(false);
					}}
				/>
			)}
			{big && <ImageModal status={big} setStatus={() => setBig(false)} />}
		</MapContainer>
	);
};

export default MapPage;
