import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const HeaderContainer = styled.section(
	() => css`
		display: grid;
		grid-template-columns: 108px 108px;
		justify-content: space-between;
		align-items: center;
		position: fixed;
		top: 20px;
		inset-inline-start:0;
		width: 100%;
		padding: 0 16px;
		div {
			color: #fff;
			background: #000;
			border-radius: 35px;
			padding: 8px;
			position: relative;
			width: 107px;
			p {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				max-width: 54px;
				font-size: 13px;
				font-weight: 600;
				line-height: 16.09px;
				text-align: center;
			}
			display: flex;
			align-items: center;
			justify-content: center;
			img {
				position: absolute;
				top: -3px;
				left: -5px;
				width: 30px;
			}
		}
	`,
);
