import { FC } from 'react';
import { SingleCardContainer } from '../css';
import Icon from 'shared/components/icon';
import { IGetAsset } from 'api/v1/assets';
import numberUtils from 'shared/utils/number';

interface ISingleCardProps {
	onClick: () => void;
	data: IGetAsset;
}

const SingleCard: FC<ISingleCardProps> = ({ data, onClick }) => {
	return (
		<SingleCardContainer onClick={onClick} selected={data.status === 2} category={data.type === 1 ? 'pet' : 'armor'}>
			<div className='top'>
				<div className='top_num'>{numberUtils.formatNumberWithSuffix(data.count)}</div>
			</div>
			<img src={data.iconUrl} alt={data.title} className='icon' />
			<div className={`status ${data.status}`}>
				{data.status === 1 ? (
					<Icon name='lock' className='status_icon' />
				) : data.status === 2 ? (
					<Icon name='tick' className='status_icon' />
				) : data.status === 4 ? (
					<Icon name='loader' className='status_icon spin' />
				) : (
					''
				)}
			</div>
			<div className='content'>
				<h3 className={data.status === 2 || data.status === 3 ? 'selected' : ''}>{data.title}</h3>
				{data.status === 2 ? (
					<p className='current'>Selected</p>
				) : data.status === 3 ? (
					<p className='current'>Use this </p>
				) : data.status === 4 ? (
					<p className='current'>Pending </p>
				) : (
					<div className='price'>
						{data.price?.diamond > 0 && <span>💎 {data.price?.diamond}</span>}
						{data.price?.education > 0 && (
							<>
								| <span>🎓 {data.price?.education}</span>
							</>
						)}
					{data.price?.food > 0 && (
							<>
								| <span>🌾 {data.price?.food}</span>
							</>
						)}
						{data.price?.prana > 0 && (
							<>
								| <span>🏡 {data.price?.prana}</span>
							</>
						)}
					</div>
				)}
			</div>
		</SingleCardContainer>
	);
};

export default SingleCard;
