import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const ImageModalContainer = styled.section(
	() => css`
    position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		img {
			width: 80%;
		}

			.close {
				background-color: #fff;
				border-radius: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				position: absolute;
				inset-inline-end: 12%;
				top: 2%;
				width: 24px;
				height: 24px;
				padding: 2px;
				box-shadow: 0px 1.3px 5.2px 0px #00000070;
				border: 1.3px solid #5e2015;
				transition: all 0.3s ease-in-out;
			}
	`,
);
