import { useEffect, useState } from 'react';
import { WalletContainer } from './css';
import { Helmet } from 'react-helmet';
import { Info, List } from './components';

const now = Date.now();
const start = 1737979200000;
const end = 1744459200000;
const TOTAL_DAYS = Math.floor((end - start )/ 86400000);
const TOTAL_SEGMENTS = 20;

const Wallet = () => {
	const [remainingDays, setRemainingDays] = useState(TOTAL_DAYS);

	useEffect(() => {
		const remain = Math.floor((end - now )/ 86400000);
		setRemainingDays(remain);
	}, []);

	const filledSegments = Math.round((remainingDays / TOTAL_DAYS) * TOTAL_SEGMENTS);

	return (
		<WalletContainer>
			<Helmet>
				<title>PinEye | Wallet</title>
			</Helmet>
			<header>
				<img src='/img/wallet-v2/header-bg.webp' alt='' className='header_bg' />
				<img src='/img/wallet-v2/header-hero.webp' alt='' className='header_hero' />

				<div className='header_overlay' />
			</header>

			<div className='content'>
				<Info />
				<div className='remain'>
					<div className='text'>
						<p className='first'>25% first part releasing to your Exchange</p>
						<p className='sec'>Time Left</p>
						<span className='third'>75% of Your</span>
						<p className='forth'>
							<span>Tokens</span> Releasing
						</p>
					</div>
					<div className='container_circle'>
						<svg width='130' height='130' viewBox='0 0 100 100'>
							{/* Background Segments */}
							{[...Array(TOTAL_SEGMENTS)].map((_, i) => {
								const angle = (i / TOTAL_SEGMENTS) * 360;
								return (
									<rect
										key={i}
										width='10'
										height='10'
										x='45'
										y='5'
										fill={i < filledSegments ? '#fff' : '#ffc107'}
										transform={`rotate(-${angle} 50 50)`}
										rx='2'
									/>
								);
							})}
						</svg>
						<div className='text_style'>
							<span className='percentageStyle'>{Math.round((remainingDays / TOTAL_DAYS) * 100) }%</span>
							<span className='daysStyle'>{remainingDays} days</span>
						</div>
					</div>
				</div>
				<List />
			</div>

			{/* {isEligible && !isEligible.isEligible && <NotEligible data={isEligible.cause} />} */}
		</WalletContainer>
	);
};
export default Wallet;
