import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const HaveModalContainer = styled.section(
	({ theme }) => css`
		padding: 32px;
		position: relative;
		text-align: center;
		color: ${theme.colors.text.primary};
		display: flex;
		flex-direction: column;
		gap: 16px;
		.head {
			width: 170px;
			height: 170px;
			position: absolute;
			top: -150px;
			inset-inline-start: 0;
			inset-inline-end: 0;
			margin: auto;
		}
		.btn {
			width: 70%;
			align-self: center;
			a {
				color: #000;
			}
		}
		.input_ {
			background: #147bdb;
			border: 1px solid #f5c21d;
			box-shadow: 0px 4px 4px 0px #00000040 inset;
			padding: 19px;
			border-radius: 10px;
			color: #fff;
			width: 80%;
			justify-self: center;
			align-self: center;
			margin-bottom: 10px;
		}
	`,
);
