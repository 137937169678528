import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const PracticeCardContainer = styled.div(
	() => css`
		position: relative;
		top: 200px;
		display: grid;
		grid-template-columns: 1fr;
		gap: 8px;
		/* padding-bottom: 120px; */
		border: 1.4px solid #f5c21d;
		border-bottom:unset;

		padding: 16px;
		border-radius: 16px;
		/* border-bottom-left-radius: 0;
		border-bottom-right-radius: 0; */
		margin-inline-start: 16px;
		margin-inline-end: 16px;
		height: calc(100vh - 300px);  
		overflow-y: auto;  
		scrollbar-width: thin;  
		scrollbar-color:  transparent;  

		.card {
			border-radius: 14px;
			padding: 16px;
			display: flex;
			gap: 8px;
			cursor: pointer;
			transition: 0.3s;
			background: #0d5aa4;
			justify-content: space-around;
			align-items: center;
			&.disabled {
				background: #0c4c74;
			}
			img {
				width: 69px;
			}
			.card_content {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				gap: 4px;
				h4 {
					font-size: 15px;
					font-weight: 600;
					line-height: 18.57px;
				}
				p {
					font-size: 12px;
					font-weight: 400;
					line-height: 17px;
				}
				strong {
					font-size: 14px;
					font-weight: 600;
					line-height: 14px;
					display: flex;
					justify-content: space-between;
					span {
						color: #ffec2f;
					}
				}
				.day {
					display: flex;
					align-items: center;
					justify-content: flex-end;
					gap: 4px;
					font-size: 12px;
					font-weight: bold;
					line-height: 14.86px;
					margin-inline-end: 5px;
				}
				.progress_bar {
					display: flex;
					border: 1.5px solid #66d9f0;
					border-radius: 5px;
					height: 22px;
					background: #023e63;
					overflow: hidden;
				}

				.progress_segment {
					flex: 1;
					border-right: 2px solid #3993cb;
					background: #023e63;
				}

				.progress_segment:last-child {
					border-right: none;
				}

				.progress_segment.filled {
					background: linear-gradient(269.96deg, #0bea68 42.55%, #06843a 118.58%);
					box-shadow: 0px 2px 4px 0px #ffffff inset;
					box-shadow: 0px 4px 4px 0px #002fec24;
					border-radius: 4px;
					margin: 2px;
				}
				.six_day {
					font-size: 16px;
					font-weight: 600;
					line-height: 14px;

					span {
						padding: 4px;
						img {
							width: 22px;
							height: 22px;
							margin-bottom: -4px;
						}
					}
				}
			}
		}

		.container_timer {
			width: 70px;
			height: 70px;
			position: relative;
			font-size: 10px;
			font-weight: 500;
			line-height: 12.38px;
			letter-spacing: 0.04em;

			.clock {
				width: 70px;
				height: 77px;
				margin-bottom: 4px;
			}
		}
		@media (max-width: 350px) {
			top: 90px;
		}
	`,
);
