import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const ModalAcceptContainer = styled.section(
	() => css`
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-content: center;
		align-items: center;
		gap: 30px;
		height: 250px;
		.answer_item_correct {
				background-image: url(/img/academy/g_bg.webp);
				background-size: contain;
				background-position: center;
				background-repeat: no-repeat;
				width: 50%;
				height: 38px;
				text-align: center;
				align-items: center;
				display: flex;
				justify-content: center;
				font-size: 13px;
				font-weight: bold;
				line-height: 16.09px;
				letter-spacing: 0.03em;
				color: #fff;
			}
		button {
			width: 50%;
			color: #fff;
		}
	`,
);
