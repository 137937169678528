import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const WalletContainer = styled.div(
	() => css`
		position: relative;
		padding-bottom: 120px;
		background: #002554;
		/* height: 100vh; */
		header {
			position: relative;

			.header_bg {
				position: absolute;
				width: 100%;
				top: 0;
				inset-inline-end: 0;
				z-index: 1;
			}
			.header_hero {
				position: absolute;
				top: 20px;
				z-index: 3;
				width: 55vw;
				inset-inline-start: 45%;
			}
			.header_overlay {
				position: absolute;
				top: 35vw;
				width: 100%;
				height: 30vw;
				z-index: 2;
				background: linear-gradient(to bottom, transparent 0%, #002554 35%, #002554 100%);
			}
		}

		.content {
			position: relative;
			z-index: 2;
			padding: 0 16px;
			padding-top: 200px;
			gap: 16px;
			display: flex;
			flex-direction: column;
			@media (min-width: 390px) {
				padding-top: 220px;
			}
			.remain {
				display: grid;
				grid-template-columns: 1fr auto;
				color: #fff;
				margin: 8px 0;
				padding: 8px;
				.text {
					gap: 8px;
					display: flex;
					flex-direction: column;
					.first {
						font-weight: bold;
						font-size: 13px;
						line-height: 21px;
						letter-spacing: 0px;
					}
					.sec {
						font-weight: 600;
						font-size: 19.13px;
						line-height: 21.04px;
					}
					.third {
						font-weight: 800;
						font-size: 25.82px;
						line-height: 21.04px;
						color: #f5c21d;
					}
					.forth {
						font-weight: 800;
						font-size: 17.22px;
						line-height: 21.04px;
						span {
							color: #f5c21d;
						}
					}
				}

				.container_circle {
					position: relative;
					width: 130px;
					height: 130px;
					display: flex;
					align-items: center;
					justify-content: center;
					.text_style {
						position: absolute;
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						color: #fff;
						inset-inline-end: 22%;
						top: 32%;
						.percentageStyle {
							font-size: 22px;
							font-weight: bold;
							color: #ffc107;
						}
						.daysStyle {
							color: white;
							font-size: 17px;
							line-height: 16px;
							letter-spacing: 0.93px;
							font-weight: bold;
						}
					}
				}
			}
		}
		.btn {
			width: 100%;
			justify-self: center;

			margin-top: 32px;
		}
		h4 {
			margin-top: 25px;
			margin-bottom: 5px;
			color: #fff;
			text-align: center;
			font-size: 20px;
			font-weight: bold;
			line-height: 26px;
			text-align: center;
		}


		.light_circle {
			background: radial-gradient(43.04% 43.04% at 50% 50%, #07549e 0%, rgba(1, 39, 86, 0) 100%);
			position: fixed;
			width: 646px;
			height: 335px;
			left: -68px;
			top: 50vh;
		}
	`,
);
