import React, { useState } from 'react';
import { TabsContainer, Tab } from '../css';
import { ArrowDown, ArrowRight } from 'shared/components/icons';

interface TabsProps {
	setActiveTab: (tab: 'armor' | 'pet') => void;
}

const Tabs: React.FC<TabsProps> = ({ setActiveTab }) => {
	const [activeTab, setLocalActiveTab] = useState<'armor' | 'pet'>('armor');

	const handleTabChange = (tab: 'armor' | 'pet') => {
		setLocalActiveTab(tab);
		setActiveTab(tab);
	};

	return (
		<TabsContainer>
			<Tab isActive={activeTab === 'armor'} onClick={() => handleTabChange('armor')}>
				Clothes {activeTab === 'armor' ? <ArrowDown className='icon' /> : <ArrowRight className='icon' />}
			</Tab>
			<Tab isActive={activeTab === 'pet'} onClick={() => handleTabChange('pet')}>
				Pet {activeTab === 'pet' ? <ArrowDown className='icon' /> : <ArrowRight className='icon' />}
			</Tab>
		</TabsContainer>
	);
};

export default Tabs;
