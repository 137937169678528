import { ImgHTMLAttributes } from 'react';

const iconHash = {
	calendar_flat: '/img/icon/calendar_flat.webp',
	cmc_flat: '/img/icon/cmc.webp',
	telegram_flat: '/img/icon/telegram.webp',
	web_flat: '/img/icon/web.webp',
	x_flat: '/img/icon/x.webp',
	clock: '/img/icon/clock.webp',
	calendar: './img/icon/calender.webp',
	colored_calendar: './img/icon/colored-calendar.webp',
	dollor_with_border: './img/icon/dollor-with-border.webp',
	tick: './img/icon/tick.webp',
	instagram: '/img/icon/instagram-logo.webp',
	copy_trading: '/img/earn/copy-trade-modal.webp',
	arrow: '/img/icon/arrow.webp',
	loader: '/img/icon/loader.webp',
	cross: '/img/icon/cross.webp',
	play_btn: '/img/icon/play-btn.webp',
	tick_1: '/img/icon/tick-1.webp',
	warning: 'img/icon/warning.webp',
	lock: 'img/icon/lock.webp',
	loader_red:'/img/icon/loader-red.webp',
};

type IconProps = {
	name: keyof typeof iconHash;
	alt?: string;
} & Omit<ImgHTMLAttributes<HTMLImageElement>, 'src'>;

const Icon = ({ name, alt = 'icon', ...props }: IconProps) => {
	return <img src={iconHash[name]} alt={alt} {...props} />;
};

export default Icon;
