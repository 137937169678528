import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { WalkthroughContainer } from './walkthrough.style';
import { useRef, useState } from 'react';
import { Swiper as SwiperType } from 'swiper/types';
import { useDispatch } from 'react-redux';
import { changeWalkthrough } from 'store/reducers/profile';
import ButtonNew from '../button_new';

const Walkthrough = () => {
	const swiperRef = useRef<SwiperType | null>(null);
	const [activeIndex, setActiveIndex] = useState<number>(0);

	const lastEligibleIndex = 3;

	const dispatch = useDispatch();

	const swiperCards = [
		{ img: '/img/walkthrough/11.webp' },
		{
			img: '/img/walkthrough/22.webp',
		},
		{
			img: '/img/walkthrough/33.webp',
		},
		{
			img: '/img/walkthrough/44.webp',
		},
	];

	const onNextClickHandler = () => {
		if (swiperRef.current && activeIndex !== lastEligibleIndex) {
			swiperRef.current.slideNext();
			if (activeIndex === 1) {
				swiperRef.current.allowSlidePrev = false;
			}
		}

		if (activeIndex === lastEligibleIndex) {
			dispatch(changeWalkthrough());
		}
	};

	return (
		<WalkthroughContainer>
			<Swiper
				spaceBetween={50}
				slidesPerView={1}
				onSlideChange={swiper => {
					if (swiper.activeIndex === lastEligibleIndex) {
						swiper.allowSlidePrev = false;
					}
					setActiveIndex(swiper.activeIndex);
				}}
				className='mySwiper'
				autoplay={{
					delay: 5000,
				}}
				pagination={true}
				navigation={{ nextEl: 'next_btn', prevEl: 'prev_btn' }}
				modules={[Pagination, Navigation]}
				onSwiper={swiper => {
					swiperRef.current = swiper;
				}}
			>
				{swiperCards.map((item, index) => (
					<SwiperSlide key={`swiper_item_${index}`}>
						<div className='slider_item'>
							<div className='img_container'>
								<img src={item.img} alt='' />
							</div>
						</div>
					</SwiperSlide>
				))}

				<ButtonNew
					className={`next_btn ${activeIndex === lastEligibleIndex ? 'play' : ''}`}
					variant='thirty'
					onClick={onNextClickHandler}
				>
					{activeIndex < lastEligibleIndex ? 'Next' : 'Let’s Go'}
				</ButtonNew>
			</Swiper>
		</WalkthroughContainer>
	);
};

export default Walkthrough;
